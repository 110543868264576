import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};


export type App = {
  __typename?: 'App';
  consumerCountry?: Maybe<Scalars['String']>;
  consumerCountryCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mixinPromo?: Maybe<Array<MixinPromo>>;
  operatingCountries: Array<Country>;
};

export enum AvailableStatus {
  Available = 'available',
  Unavailable = 'unavailable'
}

/** Autogenerated return type of CancelMartMembership. */
export type CancelMartMembershipPayload = {
  __typename?: 'CancelMartMembershipPayload';
  errors?: Maybe<Array<Error>>;
  membership?: Maybe<Membership>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  popular: Scalars['Boolean'];
};

/** Autogenerated return type of CollectStoreOrder. */
export type CollectStoreOrderPayload = {
  __typename?: 'CollectStoreOrderPayload';
  errors?: Maybe<Array<Error>>;
  storeOrder?: Maybe<StoreOrder>;
};

export type Consumer = {
  __typename?: 'Consumer';
  countryCode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCashier?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nationalNumber: Scalars['String'];
  newsletterSubscribed: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  country: Scalars['String'];
  currency: Currency;
  id: Scalars['ID'];
};

/** Autogenerated return type of CreateMartMembership. */
export type CreateMartMembershipPayload = {
  __typename?: 'CreateMartMembershipPayload';
  checkoutSessionId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

export type CreateMixOrderInput = {
  outletId: Scalars['ID'];
  platform?: Maybe<OrderPlatform>;
  paymentMethod: PaymentMethod;
  cutlery?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryService?: Maybe<DeliveryService>;
  deliveryInstruction?: Maybe<Scalars['String']>;
  deliveryMetadata?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  storeOrdersAttributes: Array<CreateMixOrderStoreOrderInput>;
};

/** Autogenerated return type of CreateMixOrder. */
export type CreateMixOrderPayload = {
  __typename?: 'CreateMixOrderPayload';
  checkoutSessionId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  mixOrder?: Maybe<MixOrder>;
};

export type CreateMixOrderStoreOrderInput = {
  storeId: Scalars['ID'];
  menuItems: Array<CreateOrderItemsInput>;
};

export type CreateOrderItemsInput = {
  id: Scalars['ID'];
  menuOptionItemIds?: Maybe<Array<Scalars['ID']>>;
  remark?: Maybe<Scalars['String']>;
};

export type CreateStoreOrderRatingInput = {
  storeMixOrderId: Scalars['ID'];
  rate: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateStoreOrderRating. */
export type CreateStoreOrderRatingPayload = {
  __typename?: 'CreateStoreOrderRatingPayload';
  errors?: Maybe<Array<Error>>;
  rating?: Maybe<Rating>;
  storeOrder?: Maybe<StoreOrder>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  id: Scalars['ID'];
  paymentMethods: Array<PaymentMethod>;
  symbol: Scalars['String'];
};

/** Autogenerated return type of DeleteProfile. */
export type DeleteProfilePayload = {
  __typename?: 'DeleteProfilePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeliveryBooking = {
  __typename?: 'DeliveryBooking';
  bookingId?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  integration: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  riderName?: Maybe<Scalars['String']>;
  riderPhoneNumber?: Maybe<Scalars['String']>;
  riderVehicleNo?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
};

export enum DeliveryOption {
  Delivery = 'delivery',
  Pickup = 'pickup',
  DineIn = 'dine_in'
}

export enum DeliveryService {
  Pandago = 'pandago',
  Lalamove = 'lalamove'
}

export type DiscountCodeOutlet = {
  __typename?: 'DiscountCodeOutlet';
  discounts: Array<DiscountCodeOutletDiscounts>;
  id: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DiscountCodeOutletDiscounts = {
  __typename?: 'DiscountCodeOutletDiscounts';
  appliedAmount: Scalars['Float'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};


export type LoginInput = {
  phoneNumber: Scalars['String'];
  phoneCode: Scalars['String'];
  registrationId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
};

/** Autogenerated return type of Login. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<Consumer>;
};

export enum MartMembershipPlan {
  Basic = 'basic'
}

export type MartSpendingTracker = {
  __typename?: 'MartSpendingTracker';
  id: Scalars['ID'];
  spendActual: Scalars['Float'];
  spendRegular: Scalars['Float'];
  spendSaved: Scalars['Float'];
};

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  name: Scalars['String'];
  planType: Scalars['String'];
  price: Scalars['Float'];
  state: Scalars['String'];
  variant: Scalars['String'];
};

export type MenuCategory = {
  __typename?: 'MenuCategory';
  availableStatus: AvailableStatus;
  id: Scalars['ID'];
  menuItems?: Maybe<Array<MenuItem>>;
  name: Scalars['String'];
};

export type MenuItem = {
  __typename?: 'MenuItem';
  availableStatus: AvailableStatus;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<Array<MenuCategory>>;
  menuOptions?: Maybe<Array<MenuOption>>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  priceWithMembership?: Maybe<Scalars['Float']>;
  priceWithPromo?: Maybe<Scalars['Float']>;
  priceWithTax?: Maybe<Scalars['Float']>;
  soldCount: Scalars['Int'];
  stock: Scalars['Int'];
  store: Store;
  withMembership?: Maybe<Scalars['Boolean']>;
  withPromo?: Maybe<Scalars['Boolean']>;
};

export type MenuOption = {
  __typename?: 'MenuOption';
  availableStatus: AvailableStatus;
  id: Scalars['ID'];
  itemOptions?: Maybe<Array<MenuItem>>;
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MenuSection = {
  __typename?: 'MenuSection';
  id: Scalars['ID'];
  menuCategories?: Maybe<Array<MenuCategory>>;
  name: Scalars['String'];
  openingHours?: Maybe<Array<OpeningHour>>;
};

export type MixDiscountStore = {
  friendlyPath: Scalars['String'];
  subtotal: Scalars['Float'];
};

export type MixOrder = {
  __typename?: 'MixOrder';
  acceptedTime?: Maybe<Scalars['ISO8601DateTime']>;
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  cutlery: Scalars['Boolean'];
  deliveryBooking?: Maybe<DeliveryBooking>;
  deliveryFee: Scalars['Float'];
  deliveryOption: DeliveryOption;
  discountCode?: Maybe<Scalars['String']>;
  finalPrice: Scalars['Float'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  orderCode: Scalars['String'];
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  outlet: Outlet;
  paymentMethod: PaymentMethod;
  promo: Scalars['Float'];
  rating?: Maybe<Rating>;
  state: OrderState;
  store: Outlet;
  storeOrders?: Maybe<Array<StoreOrder>>;
  tax: Scalars['Float'];
};

export type MixinPromo = {
  __typename?: 'MixinPromo';
  amount: Scalars['Int'];
  capped: Scalars['Int'];
  category: MixinPromoCategory;
  id: Scalars['ID'];
  minSpend: Scalars['Int'];
  outletIds: Array<Scalars['ID']>;
  percentage: Scalars['Float'];
  storesCount: Scalars['Int'];
};

export enum MixinPromoCategory {
  Percentage = 'percentage',
  Flat = 'flat'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelMartMembership?: Maybe<CancelMartMembershipPayload>;
  collectStoreOrder?: Maybe<CollectStoreOrderPayload>;
  createMartMembership?: Maybe<CreateMartMembershipPayload>;
  createMixOrder?: Maybe<CreateMixOrderPayload>;
  createStoreOrderRating?: Maybe<CreateStoreOrderRatingPayload>;
  deleteProfile?: Maybe<DeleteProfilePayload>;
  login?: Maybe<LoginPayload>;
  removePlace?: Maybe<RemovePlacePayload>;
  requestPhoneCode?: Maybe<RequestPhoneCodePayload>;
  savePlace?: Maybe<SavePlacePayload>;
  updateGcmToken?: Maybe<UpdateGcmTokenPayload>;
  updatePlace?: Maybe<UpdatePlacePayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updateProfilePrompt?: Maybe<UpdateProfilePromptPayload>;
};


export type MutationCollectStoreOrderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMartMembershipArgs = {
  planId: MartMembershipPlan;
};


export type MutationCreateMixOrderArgs = {
  input: CreateMixOrderInput;
};


export type MutationCreateStoreOrderRatingArgs = {
  input: CreateStoreOrderRatingInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRemovePlaceArgs = {
  id: Scalars['ID'];
};


export type MutationRequestPhoneCodeArgs = {
  input: RequestPhoneCodeInput;
};


export type MutationSavePlaceArgs = {
  input: SavePlaceInput;
};


export type MutationUpdateGcmTokenArgs = {
  input: UpdateGcmTokenInput;
};


export type MutationUpdatePlaceArgs = {
  input: UpdatePlaceInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateProfilePromptArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  newsletterSubscribed?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Platform>;
};

/** Attributes for Infinite Scrolling */
export type OffsetAttributes = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type OpeningHour = {
  __typename?: 'OpeningHour';
  close: Scalars['String'];
  day: Scalars['String'];
  id: Scalars['ID'];
  open: Scalars['String'];
};

export type OrderMenuItemDetails = {
  __typename?: 'OrderMenuItemDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrderMenuItems = {
  __typename?: 'OrderMenuItems';
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Array<OrderMenuItemDetails>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
};

export type OrderMenuItemsWithCount = {
  __typename?: 'OrderMenuItemsWithCount';
  count: Scalars['Int'];
  id: Scalars['ID'];
  item: OrderMenuItems;
};

export enum OrderPlatform {
  Web = 'web',
  Android = 'android',
  Ios = 'ios',
  AndroidPos = 'android_pos',
  IosPos = 'ios_pos'
}

export enum OrderState {
  Unfulfilled = 'unfulfilled',
  Accepted = 'accepted',
  Readied = 'readied',
  Collected = 'collected',
  Finished = 'finished',
  Cancelled = 'cancelled'
}

export type Outlet = {
  __typename?: 'Outlet';
  address: Scalars['String'];
  close?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  friendlyPath: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  noticeImage?: Maybe<PopUpImage>;
  open?: Maybe<Scalars['String']>;
  slides?: Maybe<Array<Scalars['String']>>;
  slidesUrls?: Maybe<Array<Scalars['String']>>;
  stores?: Maybe<Array<Store>>;
};


export type OutletStoresArgs = {
  deliveryOption?: Maybe<DeliveryOption>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum PaymentMethod {
  Fpx = 'fpx',
  Card = 'card',
  Cash = 'cash',
  Grabpay = 'grabpay',
  Qr = 'qr'
}

export enum Platform {
  Web = 'web',
  Android = 'android',
  Ios = 'ios',
  AndroidPos = 'android_pos',
  IosPos = 'ios_pos'
}

export type PopUpImage = {
  __typename?: 'PopUpImage';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  image: Scalars['String'];
  name: Scalars['String'];
};

export type PreviewDelivery = {
  __typename?: 'PreviewDelivery';
  deliveryEta?: Maybe<Scalars['String']>;
  deliveryMetadata?: Maybe<Scalars['String']>;
  deliveryPrice: Scalars['Float'];
  error: Scalars['String'];
  id: Scalars['ID'];
  serviceName: Scalars['String'];
  serviceType: DeliveryService;
};

export type Promo = {
  __typename?: 'Promo';
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  id: Scalars['ID'];
  menuItems: Array<MenuItem>;
  name: Scalars['String'];
  promoVariant: PromoVariant;
  store: Store;
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
};

export enum PromoVariant {
  Fixed = 'fixed',
  Percentage = 'percentage'
}

export type Query = {
  __typename?: 'Query';
  announcement?: Maybe<Scalars['String']>;
  app?: Maybe<App>;
  applyMixDiscountCode?: Maybe<DiscountCodeOutlet>;
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  mart?: Maybe<Store>;
  martMembershipsPlan?: Maybe<Membership>;
  martMembershipsSubscribed?: Maybe<Membership>;
  martSearch: Array<MenuItem>;
  me?: Maybe<Consumer>;
  menuItem?: Maybe<MenuItem>;
  menuItems?: Maybe<Array<MenuItem>>;
  mixMobileFirstOrder?: Maybe<Scalars['Boolean']>;
  mixOrder?: Maybe<MixOrder>;
  mixOrders?: Maybe<Array<MixOrder>>;
  orderReceipt?: Maybe<Scalars['String']>;
  ordersToRate?: Maybe<Array<StoreOrder>>;
  outlet?: Maybe<Outlet>;
  outlets?: Maybe<Array<Outlet>>;
  outletsWithMart?: Maybe<Array<Outlet>>;
  popUpImages?: Maybe<Array<PopUpImage>>;
  previewLalamove?: Maybe<PreviewDelivery>;
  previewPandago?: Maybe<PreviewDelivery>;
  savedPlaces?: Maybe<Array<SavedPlace>>;
  spendingTracker?: Maybe<MartSpendingTracker>;
  store?: Maybe<Store>;
  stores?: Maybe<Array<Store>>;
};


export type QueryApplyMixDiscountCodeArgs = {
  discountCode: Scalars['String'];
  subtotal: Scalars['Float'];
  deliveryOption: DeliveryOption;
  paymentMethod?: Maybe<PaymentMethod>;
  storeData: Array<MixDiscountStore>;
  platform?: Maybe<Platform>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryMartArgs = {
  outletFriendlyPath: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type QueryMartSearchArgs = {
  q: Scalars['String'];
  storeFriendlyPath: Scalars['String'];
};


export type QueryMenuItemArgs = {
  id: Scalars['ID'];
};


export type QueryMenuItemsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMixOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMixOrdersArgs = {
  variant?: Maybe<StoreVariant>;
  states: Array<OrderState>;
  offsetAttributes: OffsetAttributes;
};


export type QueryOrderReceiptArgs = {
  id: Scalars['ID'];
};


export type QueryOutletArgs = {
  friendlyPath: Scalars['String'];
};


export type QueryOutletsArgs = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type QueryOutletsWithMartArgs = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type QueryPreviewLalamoveArgs = {
  outletId?: Maybe<Scalars['ID']>;
  storeId?: Maybe<Scalars['ID']>;
  address: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};


export type QueryPreviewPandagoArgs = {
  outletId?: Maybe<Scalars['ID']>;
  storeId?: Maybe<Scalars['ID']>;
  address: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};


export type QueryStoreArgs = {
  friendlyPath: Scalars['String'];
};


export type QueryStoresArgs = {
  deliveryOption: DeliveryOption;
  outletFriendlyPaths?: Maybe<Array<Scalars['String']>>;
  searchString?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type Rating = {
  __typename?: 'Rating';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  rate: Scalars['Int'];
  storeMixOrderId: Scalars['ID'];
};

/** Autogenerated return type of RemovePlace. */
export type RemovePlacePayload = {
  __typename?: 'RemovePlacePayload';
  errors?: Maybe<Array<Error>>;
  savedPlace?: Maybe<SavedPlace>;
};

export type RequestPhoneCodeInput = {
  phoneNumber: Scalars['String'];
  appHash?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RequestPhoneCode. */
export type RequestPhoneCodePayload = {
  __typename?: 'RequestPhoneCodePayload';
  errors?: Maybe<Array<Error>>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SavePlaceInput = {
  name: Scalars['String'];
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Autogenerated return type of SavePlace. */
export type SavePlacePayload = {
  __typename?: 'SavePlacePayload';
  errors?: Maybe<Array<Error>>;
  savedPlace?: Maybe<SavedPlace>;
};

export type SavedPlace = {
  __typename?: 'SavedPlace';
  address: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  averageRating?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  country: Scalars['String'];
  deliveryOption: Array<DeliveryOption>;
  deliveryServices?: Maybe<Array<DeliveryService>>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  distanceToDeliveryDestination?: Maybe<Scalars['Float']>;
  friendlyPath: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  menuSection?: Maybe<MenuSection>;
  name: Scalars['String'];
  ongoingPromos?: Maybe<Array<Promo>>;
  outlet: Outlet;
  phoneNumber: Scalars['String'];
  priceSymbol: Scalars['String'];
  ratings?: Maybe<Array<Rating>>;
  ratingsCount?: Maybe<Scalars['Int']>;
  taxPercentage: Scalars['Int'];
};


export type StoreDistanceToDeliveryDestinationArgs = {
  coordinates?: Maybe<Array<Scalars['Float']>>;
};

export type StoreOrder = {
  __typename?: 'StoreOrder';
  cabinetSlotNumber?: Maybe<Scalars['String']>;
  cancelMessage?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  finalPrice: Scalars['Float'];
  id: Scalars['ID'];
  mixOrder: MixOrder;
  orderMenuItemsWithCount: Array<OrderMenuItemsWithCount>;
  originalPrice: Scalars['Float'];
  promo: Scalars['Float'];
  rating?: Maybe<Rating>;
  state: OrderState;
  store: Store;
  tax: Scalars['Float'];
};

export enum StoreVariant {
  Food = 'food',
  Mart = 'mart'
}

export type UpdateGcmTokenInput = {
  idToDelete?: Maybe<Scalars['String']>;
  idToAdd?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateGcmToken. */
export type UpdateGcmTokenPayload = {
  __typename?: 'UpdateGcmTokenPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<Consumer>;
};

export type UpdatePlaceInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdatePlace. */
export type UpdatePlacePayload = {
  __typename?: 'UpdatePlacePayload';
  errors?: Maybe<Array<Error>>;
  savedPlace?: Maybe<SavedPlace>;
};

export type UpdateProfileInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<Consumer>;
};

/** Autogenerated return type of UpdateProfilePrompt. */
export type UpdateProfilePromptPayload = {
  __typename?: 'UpdateProfilePromptPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<Consumer>;
};

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail'>
);

export type MeFragment = (
  { __typename?: 'Consumer' }
  & Pick<Consumer, 'id' | 'name' | 'email' | 'phoneNumber' | 'isCashier'>
);

export type NoticeImageFragment = (
  { __typename?: 'PopUpImage' }
  & Pick<PopUpImage, 'id' | 'name' | 'image'>
);

export type PreviewDeliveryFragment = (
  { __typename?: 'PreviewDelivery' }
  & Pick<PreviewDelivery, 'id' | 'serviceName' | 'serviceType' | 'deliveryPrice' | 'deliveryEta' | 'deliveryMetadata' | 'error'>
);

export type StoreListFragment = (
  { __typename?: 'Store' }
  & Pick<Store, 'id' | 'friendlyPath' | 'name' | 'distance' | 'image' | 'active' | 'averageRating' | 'priceSymbol'>
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, ongoingPromos?: Maybe<Array<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'id' | 'name'>
  )>>, outlet: (
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'friendlyPath'>
  ) }
);

export type LoginMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  phoneCode: Scalars['String'];
  registrationId?: Maybe<Scalars['String']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'jwt'>
    & { me?: Maybe<(
      { __typename?: 'Consumer' }
      & MeFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RequestPhoneCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type RequestPhoneCodeMutation = (
  { __typename?: 'Mutation' }
  & { requestPhoneCode?: Maybe<(
    { __typename?: 'RequestPhoneCodePayload' }
    & Pick<RequestPhoneCodePayload, 'phoneNumber'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateGcmTokenMutationVariables = Exact<{
  idToDelete?: Maybe<Scalars['String']>;
  idToAdd?: Maybe<Scalars['String']>;
}>;


export type UpdateGcmTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateGcmToken?: Maybe<(
    { __typename?: 'UpdateGcmTokenPayload' }
    & { me?: Maybe<(
      { __typename?: 'Consumer' }
      & Pick<Consumer, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CancelMartMembershipMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelMartMembershipMutation = (
  { __typename?: 'Mutation' }
  & { cancelMartMembership?: Maybe<(
    { __typename?: 'CancelMartMembershipPayload' }
    & { membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'id' | 'name' | 'price'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMartMembershipMutationVariables = Exact<{
  planId: MartMembershipPlan;
}>;


export type CreateMartMembershipMutation = (
  { __typename?: 'Mutation' }
  & { createMartMembership?: Maybe<(
    { __typename?: 'CreateMartMembershipPayload' }
    & Pick<CreateMartMembershipPayload, 'checkoutSessionId'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CollectStoreOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CollectStoreOrderMutation = (
  { __typename?: 'Mutation' }
  & { collectStoreOrder?: Maybe<(
    { __typename?: 'CollectStoreOrderPayload' }
    & { storeOrder?: Maybe<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMixOrderMutationVariables = Exact<{
  outletId: Scalars['ID'];
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryService?: Maybe<DeliveryService>;
  deliveryInstruction?: Maybe<Scalars['String']>;
  deliveryMetadata?: Maybe<Scalars['String']>;
  cutlery?: Maybe<Scalars['Boolean']>;
  discountCode?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  platform?: Maybe<OrderPlatform>;
  storeOrdersAttributes: Array<CreateMixOrderStoreOrderInput> | CreateMixOrderStoreOrderInput;
}>;


export type CreateMixOrderMutation = (
  { __typename?: 'Mutation' }
  & { createMixOrder?: Maybe<(
    { __typename?: 'CreateMixOrderPayload' }
    & Pick<CreateMixOrderPayload, 'checkoutSessionId'>
    & { mixOrder?: Maybe<(
      { __typename?: 'MixOrder' }
      & Pick<MixOrder, 'id' | 'orderCode'>
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateStoreOrderRatingMutationVariables = Exact<{
  storeMixOrderId: Scalars['ID'];
  rate: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
}>;


export type CreateStoreOrderRatingMutation = (
  { __typename?: 'Mutation' }
  & { createStoreOrderRating?: Maybe<(
    { __typename?: 'CreateStoreOrderRatingPayload' }
    & { storeOrder?: Maybe<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id'>
      & { rating?: Maybe<(
        { __typename?: 'Rating' }
        & Pick<Rating, 'id' | 'rate' | 'comment'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UpdateProfilePayload' }
    & { me?: Maybe<(
      { __typename?: 'Consumer' }
      & Pick<Consumer, 'id' | 'name' | 'email' | 'phoneNumber'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateProfilePromptMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  newsletterSubscribed: Scalars['Boolean'];
}>;


export type UpdateProfilePromptMutation = (
  { __typename?: 'Mutation' }
  & { updateProfilePrompt?: Maybe<(
    { __typename?: 'UpdateProfilePromptPayload' }
    & { me?: Maybe<(
      { __typename?: 'Consumer' }
      & Pick<Consumer, 'id' | 'name' | 'email' | 'phoneNumber' | 'newsletterSubscribed'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AccountPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, martMembershipsSubscribed?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'id' | 'name' | 'price'>
  )>, spendingTracker?: Maybe<(
    { __typename?: 'MartSpendingTracker' }
    & Pick<MartSpendingTracker, 'spendRegular' | 'spendActual' | 'spendSaved'>
  )> }
);

export type UpdatePromptPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdatePromptPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )> }
);

export type UpdateProfilePageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateProfilePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & Pick<Consumer, 'countryCode' | 'nationalNumber'>
    & MeFragment
  )> }
);

export type CategoryPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CategoryPageQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )> }
);

export type MartCartPageQueryVariables = Exact<{
  outletFriendlyPath: Scalars['String'];
}>;


export type MartCartPageQuery = (
  { __typename?: 'Query' }
  & { outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )> }
);

export type OutletMartPageQueryVariables = Exact<{
  outletFriendlyPath: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;


export type OutletMartPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'open' | 'close' | 'friendlyPath'>
  )>, mart?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'friendlyPath' | 'banner' | 'active' | 'distance' | 'deliveryOption'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'availableStatus'>
        & { menuItems?: Maybe<Array<(
          { __typename?: 'MenuItem' }
          & Pick<MenuItem, 'id' | 'name' | 'image' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'priceWithMembership' | 'withPromo' | 'withMembership' | 'availableStatus'>
        )>> }
      )>> }
    )> }
  )> }
);

export type OutletMartMenuItemPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OutletMartMenuItemPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, menuItem?: Maybe<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id' | 'name' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'priceWithMembership' | 'withPromo' | 'withMembership' | 'image' | 'availableStatus'>
    & { store: (
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'active'>
    ), menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'availableStatus'>
    )>> }
  )> }
);

export type MartPageQueryVariables = Exact<{
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;


export type MartPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, outletsWithMart?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'image' | 'open' | 'close' | 'friendlyPath' | 'distance'>
  )>> }
);

export type MartMembershipPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MartMembershipPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, martMembershipsPlan?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'id' | 'name' | 'price'>
  )>, spendingTracker?: Maybe<(
    { __typename?: 'MartSpendingTracker' }
    & Pick<MartSpendingTracker, 'spendRegular' | 'spendActual' | 'spendSaved'>
  )> }
);

export type MixOrderPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MixOrderPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )> }
);

export type MixOrdersPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MixOrdersPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )> }
);

export type OutletStorePageQueryVariables = Exact<{
  friendlyPath: Scalars['String'];
}>;


export type OutletStorePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country' | 'friendlyPath' | 'banner' | 'deliveryOption' | 'deliveryServices' | 'taxPercentage' | 'active'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'availableStatus'>
        & { menuItems?: Maybe<Array<(
          { __typename?: 'MenuItem' }
          & Pick<MenuItem, 'id' | 'name' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'withPromo' | 'image' | 'availableStatus'>
        )>> }
      )>> }
    )>, outlet: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name' | 'friendlyPath'>
    ) }
  )> }
);

export type OutletCartPageQueryVariables = Exact<{
  friendlyPath: Scalars['String'];
}>;


export type OutletCartPageQuery = (
  { __typename?: 'Query' }
  & { outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )> }
);

export type OutletPageQueryVariables = Exact<{
  friendlyPath: Scalars['String'];
}>;


export type OutletPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'image' | 'slides' | 'slidesUrls'>
    & { noticeImage?: Maybe<(
      { __typename?: 'PopUpImage' }
      & NoticeImageFragment
    )> }
  )> }
);

export type OutletStoreMenuItemPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OutletStoreMenuItemPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )>, menuItem?: Maybe<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id' | 'name' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'withPromo' | 'image' | 'availableStatus'>
    & { store: (
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name' | 'country' | 'friendlyPath' | 'active' | 'deliveryOption'>
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id'>
      ) }
    ), menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'availableStatus'>
    )>>, menuOptions?: Maybe<Array<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name' | 'min' | 'max' | 'availableStatus'>
      & { itemOptions?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'priceWithTax' | 'availableStatus'>
      )>> }
    )>> }
  )> }
);

export type SearchPageQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchPageQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'popular'>
  )>> }
);

export type AppMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type AppMetadataQuery = (
  { __typename?: 'Query' }
  & { app?: Maybe<(
    { __typename?: 'App' }
    & Pick<App, 'id' | 'consumerCountry' | 'consumerCountryCode'>
    & { operatingCountries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'country' | 'code'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol' | 'code' | 'paymentMethods'>
      ) }
    )>, mixinPromo?: Maybe<Array<(
      { __typename?: 'MixinPromo' }
      & Pick<MixinPromo, 'id' | 'outletIds' | 'storesCount' | 'amount' | 'category' | 'minSpend' | 'capped'>
    )>> }
  )> }
);

export type AnnouncementQueryVariables = Exact<{ [key: string]: never; }>;


export type AnnouncementQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'announcement'>
);

export type ApplyMixDiscountCodeQueryVariables = Exact<{
  discountCode: Scalars['String'];
  subtotal: Scalars['Float'];
  storeData: Array<MixDiscountStore> | MixDiscountStore;
  deliveryOption: DeliveryOption;
  paymentMethod?: Maybe<PaymentMethod>;
}>;


export type ApplyMixDiscountCodeQuery = (
  { __typename?: 'Query' }
  & { applyMixDiscountCode?: Maybe<(
    { __typename?: 'DiscountCodeOutlet' }
    & Pick<DiscountCodeOutlet, 'id' | 'success' | 'message'>
    & { discounts: Array<(
      { __typename?: 'DiscountCodeOutletDiscounts' }
      & Pick<DiscountCodeOutletDiscounts, 'id' | 'appliedAmount' | 'error'>
    )> }
  )> }
);

export type GetOutletsWithMartQueryVariables = Exact<{
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;


export type GetOutletsWithMartQuery = (
  { __typename?: 'Query' }
  & { outletsWithMart?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'image' | 'open' | 'close' | 'friendlyPath' | 'distance'>
  )>> }
);

export type GetMartQueryVariables = Exact<{
  outletFriendlyPath: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;


export type GetMartQuery = (
  { __typename?: 'Query' }
  & { mart?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'friendlyPath' | 'address' | 'distance' | 'phoneNumber' | 'deliveryOption' | 'banner' | 'active'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'availableStatus'>
        & { menuItems?: Maybe<Array<(
          { __typename?: 'MenuItem' }
          & Pick<MenuItem, 'id' | 'name' | 'image' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'priceWithMembership' | 'withPromo' | 'withMembership' | 'availableStatus'>
        )>> }
      )>> }
    )> }
  )> }
);

export type GetMartSearchQueryVariables = Exact<{
  searchString: Scalars['String'];
  storeFriendlyPath: Scalars['String'];
}>;


export type GetMartSearchQuery = (
  { __typename?: 'Query' }
  & { martSearch: Array<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id' | 'name' | 'image' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'priceWithMembership' | 'withPromo' | 'withMembership' | 'availableStatus'>
    & { store: (
      { __typename?: 'Store' }
      & Pick<Store, 'id'>
    ) }
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Consumer' }
    & MeFragment
  )> }
);

export type GetMembershipQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembershipQuery = (
  { __typename?: 'Query' }
  & { martMembershipsPlan?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'id' | 'name' | 'price'>
  )> }
);

export type GetSubscribedMembershipQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscribedMembershipQuery = (
  { __typename?: 'Query' }
  & { martMembershipsSubscribed?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'id' | 'name' | 'price'>
  )> }
);

export type GetSpendingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpendingQuery = (
  { __typename?: 'Query' }
  & { spendingTracker?: Maybe<(
    { __typename?: 'MartSpendingTracker' }
    & Pick<MartSpendingTracker, 'spendActual' | 'spendRegular' | 'spendSaved'>
  )> }
);

export type GetMenuItemsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetMenuItemsQuery = (
  { __typename?: 'Query' }
  & { menuItems?: Maybe<Array<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id' | 'name' | 'availableStatus'>
  )>> }
);

export type GetMenuItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMenuItemQuery = (
  { __typename?: 'Query' }
  & { menuItem?: Maybe<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id'>
    & { menuOptions?: Maybe<Array<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name' | 'min' | 'max' | 'availableStatus'>
      & { itemOptions?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'priceWithTax' | 'availableStatus'>
      )>> }
    )>> }
  )> }
);

export type GetProductItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProductItemQuery = (
  { __typename?: 'Query' }
  & { menuItem?: Maybe<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id' | 'name' | 'image' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'priceWithMembership' | 'withPromo' | 'withMembership'>
  )> }
);

export type GetMixOrdersQueryVariables = Exact<{
  variant?: Maybe<StoreVariant>;
  states: Array<OrderState> | OrderState;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetMixOrdersQuery = (
  { __typename?: 'Query' }
  & { mixOrders?: Maybe<Array<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'state' | 'country' | 'orderCode' | 'deliveryOption' | 'finalPrice' | 'acceptedTime' | 'paymentMethod'>
    & { store: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name'>
    ) }
  )>> }
);

export type GetOngoingMixOrdersQueryVariables = Exact<{
  states: Array<OrderState> | OrderState;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetOngoingMixOrdersQuery = (
  { __typename?: 'Query' }
  & { mixOrders?: Maybe<Array<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'orderCode' | 'acceptedTime' | 'finalPrice' | 'deliveryOption' | 'state' | 'paymentMethod'>
    & { store: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name'>
    ), deliveryBooking?: Maybe<(
      { __typename?: 'DeliveryBooking' }
      & Pick<DeliveryBooking, 'id' | 'eta'>
    )> }
  )>> }
);

export type GetMixOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMixOrderQuery = (
  { __typename?: 'Query' }
  & { mixOrder?: Maybe<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'state' | 'country' | 'orderId' | 'orderCode' | 'acceptedTime' | 'paymentMethod' | 'deliveryOption' | 'cutlery' | 'discountCode' | 'originalPrice' | 'tax' | 'deliveryFee' | 'promo' | 'finalPrice'>
    & { outlet: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name' | 'address'>
    ), deliveryBooking?: Maybe<(
      { __typename?: 'DeliveryBooking' }
      & Pick<DeliveryBooking, 'id' | 'integration' | 'eta'>
    )>, storeOrders?: Maybe<Array<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'state' | 'cancelMessage' | 'cabinetSlotNumber'>
      & { store: (
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name' | 'phoneNumber'>
      ), rating?: Maybe<(
        { __typename?: 'Rating' }
        & Pick<Rating, 'id' | 'rate' | 'comment'>
      )>, orderMenuItemsWithCount: Array<(
        { __typename?: 'OrderMenuItemsWithCount' }
        & Pick<OrderMenuItemsWithCount, 'id' | 'count'>
        & { item: (
          { __typename?: 'OrderMenuItems' }
          & Pick<OrderMenuItems, 'id' | 'name' | 'price' | 'remark'>
          & { details?: Maybe<Array<(
            { __typename?: 'OrderMenuItemDetails' }
            & Pick<OrderMenuItemDetails, 'id' | 'name'>
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type GetMixOrderDeliveryDetailsQueryVariables = Exact<{
  mixOrderId: Scalars['ID'];
}>;


export type GetMixOrderDeliveryDetailsQuery = (
  { __typename?: 'Query' }
  & { mixOrder?: Maybe<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'state' | 'address'>
    & { store: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'address'>
    ), deliveryBooking?: Maybe<(
      { __typename?: 'DeliveryBooking' }
      & Pick<DeliveryBooking, 'id' | 'bookingId' | 'instructions' | 'integration' | 'riderName' | 'riderPhoneNumber' | 'riderVehicleNo' | 'trackingLink'>
    )> }
  )> }
);

export type GetMixOrderRiderCoordinatesQueryVariables = Exact<{
  mixOrderId: Scalars['ID'];
}>;


export type GetMixOrderRiderCoordinatesQuery = (
  { __typename?: 'Query' }
  & { mixOrder?: Maybe<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'state' | 'latitude' | 'longitude'>
    & { deliveryBooking?: Maybe<(
      { __typename?: 'DeliveryBooking' }
      & Pick<DeliveryBooking, 'id' | 'latitude' | 'longitude'>
    )> }
  )> }
);

export type GetOrderReceiptQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrderReceiptQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'orderReceipt'>
);

export type GetOrdersToRateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersToRateQuery = (
  { __typename?: 'Query' }
  & { ordersToRate?: Maybe<Array<(
    { __typename?: 'StoreOrder' }
    & Pick<StoreOrder, 'id'>
    & { mixOrder: (
      { __typename?: 'MixOrder' }
      & Pick<MixOrder, 'acceptedTime' | 'id'>
    ), store: (
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name' | 'image'>
    ) }
  )>> }
);

export type GetOutletsQueryVariables = Exact<{
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;


export type GetOutletsQuery = (
  { __typename?: 'Query' }
  & { outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'image' | 'friendlyPath' | 'distance' | 'open' | 'close'>
  )>> }
);

export type GetOutletQueryVariables = Exact<{
  friendlyPath: Scalars['String'];
  deliveryOption: DeliveryOption;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;


export type GetOutletQuery = (
  { __typename?: 'Query' }
  & { outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'address'>
    & { stores?: Maybe<Array<(
      { __typename?: 'Store' }
      & StoreListFragment
    )>> }
  )> }
);

export type GetPopOutImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPopOutImagesQuery = (
  { __typename?: 'Query' }
  & { popUpImages?: Maybe<Array<(
    { __typename?: 'PopUpImage' }
    & NoticeImageFragment
  )>> }
);

export type PreviewPandagoQueryVariables = Exact<{
  outletId?: Maybe<Scalars['ID']>;
  storeId?: Maybe<Scalars['ID']>;
  address: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
}>;


export type PreviewPandagoQuery = (
  { __typename?: 'Query' }
  & { previewPandago?: Maybe<(
    { __typename?: 'PreviewDelivery' }
    & PreviewDeliveryFragment
  )> }
);

export type PreviewLalamoveQueryVariables = Exact<{
  outletId?: Maybe<Scalars['ID']>;
  storeId?: Maybe<Scalars['ID']>;
  address: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
}>;


export type PreviewLalamoveQuery = (
  { __typename?: 'Query' }
  & { previewLalamove?: Maybe<(
    { __typename?: 'PreviewDelivery' }
    & PreviewDeliveryFragment
  )> }
);

export type GetStoresQueryVariables = Exact<{
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  searchString?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  deliveryOption: DeliveryOption;
}>;


export type GetStoresQuery = (
  { __typename?: 'Query' }
  & { stores?: Maybe<Array<(
    { __typename?: 'Store' }
    & StoreListFragment
  )>> }
);

export type GetCartStoresByOutletQueryVariables = Exact<{
  outletFriendlyPaths?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  deliveryOption: DeliveryOption;
}>;


export type GetCartStoresByOutletQuery = (
  { __typename?: 'Query' }
  & { stores?: Maybe<Array<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'friendlyPath' | 'active'>
  )>> }
);

export type GetStoreWithMenuQueryVariables = Exact<{
  friendlyPath: Scalars['String'];
}>;


export type GetStoreWithMenuQuery = (
  { __typename?: 'Query' }
  & { store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'friendlyPath' | 'banner' | 'deliveryOption' | 'deliveryServices' | 'taxPercentage' | 'active'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'availableStatus'>
        & { menuItems?: Maybe<Array<(
          { __typename?: 'MenuItem' }
          & Pick<MenuItem, 'id' | 'name' | 'description' | 'priceWithTax' | 'priceWithPromo' | 'withPromo' | 'image' | 'availableStatus'>
        )>> }
      )>> }
    )>, outlet: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id'>
    ) }
  )> }
);

export type GetStoreDetailsQueryVariables = Exact<{
  friendlyPath: Scalars['String'];
}>;


export type GetStoreDetailsQuery = (
  { __typename?: 'Query' }
  & { store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'friendlyPath' | 'description' | 'averageRating' | 'ratingsCount'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>>, ongoingPromos?: Maybe<Array<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'name'>
    )>>, ratings?: Maybe<Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'rate'>
    )>>, menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
      & { openingHours?: Maybe<Array<(
        { __typename?: 'OpeningHour' }
        & Pick<OpeningHour, 'id' | 'day' | 'open' | 'close'>
      )>> }
    )> }
  )> }
);

export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on Consumer {
  id
  name
  email
  phoneNumber
  isCashier
}
    `;
export const NoticeImageFragmentDoc = gql`
    fragment NoticeImage on PopUpImage {
  id
  name
  image
}
    `;
export const PreviewDeliveryFragmentDoc = gql`
    fragment PreviewDelivery on PreviewDelivery {
  id
  serviceName
  serviceType
  deliveryPrice
  deliveryEta
  deliveryMetadata
  error
}
    `;
export const StoreListFragmentDoc = gql`
    fragment StoreList on Store {
  id
  friendlyPath
  name
  distance
  image
  active
  averageRating
  priceSymbol
  categories {
    id
    name
  }
  ongoingPromos {
    id
    name
  }
  outlet {
    id
    friendlyPath
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($phoneNumber: String!, $phoneCode: String!, $registrationId: String) {
  login(
    input: {phoneNumber: $phoneNumber, phoneCode: $phoneCode, registrationId: $registrationId}
  ) {
    jwt
    me {
      ...Me
    }
    errors {
      ...Error
    }
  }
}
    ${MeFragmentDoc}
${ErrorFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneCode: // value for 'phoneCode'
 *      registrationId: // value for 'registrationId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RequestPhoneCodeDocument = gql`
    mutation RequestPhoneCode($phoneNumber: String!) {
  requestPhoneCode(input: {phoneNumber: $phoneNumber}) {
    phoneNumber
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RequestPhoneCodeMutationFn = Apollo.MutationFunction<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>;

/**
 * __useRequestPhoneCodeMutation__
 *
 * To run a mutation, you first call `useRequestPhoneCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPhoneCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPhoneCodeMutation, { data, loading, error }] = useRequestPhoneCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRequestPhoneCodeMutation(baseOptions?: Apollo.MutationHookOptions<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>) {
        return Apollo.useMutation<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>(RequestPhoneCodeDocument, baseOptions);
      }
export type RequestPhoneCodeMutationHookResult = ReturnType<typeof useRequestPhoneCodeMutation>;
export type RequestPhoneCodeMutationResult = Apollo.MutationResult<RequestPhoneCodeMutation>;
export type RequestPhoneCodeMutationOptions = Apollo.BaseMutationOptions<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>;
export const UpdateGcmTokenDocument = gql`
    mutation UpdateGcmToken($idToDelete: String, $idToAdd: String) {
  updateGcmToken(input: {idToDelete: $idToDelete, idToAdd: $idToAdd}) {
    me {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateGcmTokenMutationFn = Apollo.MutationFunction<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>;

/**
 * __useUpdateGcmTokenMutation__
 *
 * To run a mutation, you first call `useUpdateGcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGcmTokenMutation, { data, loading, error }] = useUpdateGcmTokenMutation({
 *   variables: {
 *      idToDelete: // value for 'idToDelete'
 *      idToAdd: // value for 'idToAdd'
 *   },
 * });
 */
export function useUpdateGcmTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>) {
        return Apollo.useMutation<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>(UpdateGcmTokenDocument, baseOptions);
      }
export type UpdateGcmTokenMutationHookResult = ReturnType<typeof useUpdateGcmTokenMutation>;
export type UpdateGcmTokenMutationResult = Apollo.MutationResult<UpdateGcmTokenMutation>;
export type UpdateGcmTokenMutationOptions = Apollo.BaseMutationOptions<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>;
export const CancelMartMembershipDocument = gql`
    mutation CancelMartMembership {
  cancelMartMembership {
    membership {
      id
      name
      price
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CancelMartMembershipMutationFn = Apollo.MutationFunction<CancelMartMembershipMutation, CancelMartMembershipMutationVariables>;

/**
 * __useCancelMartMembershipMutation__
 *
 * To run a mutation, you first call `useCancelMartMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMartMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMartMembershipMutation, { data, loading, error }] = useCancelMartMembershipMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelMartMembershipMutation(baseOptions?: Apollo.MutationHookOptions<CancelMartMembershipMutation, CancelMartMembershipMutationVariables>) {
        return Apollo.useMutation<CancelMartMembershipMutation, CancelMartMembershipMutationVariables>(CancelMartMembershipDocument, baseOptions);
      }
export type CancelMartMembershipMutationHookResult = ReturnType<typeof useCancelMartMembershipMutation>;
export type CancelMartMembershipMutationResult = Apollo.MutationResult<CancelMartMembershipMutation>;
export type CancelMartMembershipMutationOptions = Apollo.BaseMutationOptions<CancelMartMembershipMutation, CancelMartMembershipMutationVariables>;
export const CreateMartMembershipDocument = gql`
    mutation CreateMartMembership($planId: MartMembershipPlan!) {
  createMartMembership(planId: $planId) {
    checkoutSessionId
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMartMembershipMutationFn = Apollo.MutationFunction<CreateMartMembershipMutation, CreateMartMembershipMutationVariables>;

/**
 * __useCreateMartMembershipMutation__
 *
 * To run a mutation, you first call `useCreateMartMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMartMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMartMembershipMutation, { data, loading, error }] = useCreateMartMembershipMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreateMartMembershipMutation(baseOptions?: Apollo.MutationHookOptions<CreateMartMembershipMutation, CreateMartMembershipMutationVariables>) {
        return Apollo.useMutation<CreateMartMembershipMutation, CreateMartMembershipMutationVariables>(CreateMartMembershipDocument, baseOptions);
      }
export type CreateMartMembershipMutationHookResult = ReturnType<typeof useCreateMartMembershipMutation>;
export type CreateMartMembershipMutationResult = Apollo.MutationResult<CreateMartMembershipMutation>;
export type CreateMartMembershipMutationOptions = Apollo.BaseMutationOptions<CreateMartMembershipMutation, CreateMartMembershipMutationVariables>;
export const CollectStoreOrderDocument = gql`
    mutation CollectStoreOrder($id: ID!) {
  collectStoreOrder(id: $id) {
    storeOrder {
      id
      state
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CollectStoreOrderMutationFn = Apollo.MutationFunction<CollectStoreOrderMutation, CollectStoreOrderMutationVariables>;

/**
 * __useCollectStoreOrderMutation__
 *
 * To run a mutation, you first call `useCollectStoreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectStoreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectStoreOrderMutation, { data, loading, error }] = useCollectStoreOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCollectStoreOrderMutation(baseOptions?: Apollo.MutationHookOptions<CollectStoreOrderMutation, CollectStoreOrderMutationVariables>) {
        return Apollo.useMutation<CollectStoreOrderMutation, CollectStoreOrderMutationVariables>(CollectStoreOrderDocument, baseOptions);
      }
export type CollectStoreOrderMutationHookResult = ReturnType<typeof useCollectStoreOrderMutation>;
export type CollectStoreOrderMutationResult = Apollo.MutationResult<CollectStoreOrderMutation>;
export type CollectStoreOrderMutationOptions = Apollo.BaseMutationOptions<CollectStoreOrderMutation, CollectStoreOrderMutationVariables>;
export const CreateMixOrderDocument = gql`
    mutation CreateMixOrder($outletId: ID!, $deliveryOption: DeliveryOption, $deliveryService: DeliveryService, $deliveryInstruction: String, $deliveryMetadata: String, $cutlery: Boolean, $discountCode: String, $paymentMethod: PaymentMethod!, $address: String, $latitude: Float, $longitude: Float, $platform: OrderPlatform, $storeOrdersAttributes: [CreateMixOrderStoreOrderInput!]!) {
  createMixOrder(
    input: {outletId: $outletId, deliveryOption: $deliveryOption, deliveryService: $deliveryService, deliveryInstruction: $deliveryInstruction, deliveryMetadata: $deliveryMetadata, cutlery: $cutlery, discountCode: $discountCode, paymentMethod: $paymentMethod, address: $address, latitude: $latitude, longitude: $longitude, platform: $platform, storeOrdersAttributes: $storeOrdersAttributes}
  ) {
    checkoutSessionId
    mixOrder {
      id
      orderCode
      outlet {
        id
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMixOrderMutationFn = Apollo.MutationFunction<CreateMixOrderMutation, CreateMixOrderMutationVariables>;

/**
 * __useCreateMixOrderMutation__
 *
 * To run a mutation, you first call `useCreateMixOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMixOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMixOrderMutation, { data, loading, error }] = useCreateMixOrderMutation({
 *   variables: {
 *      outletId: // value for 'outletId'
 *      deliveryOption: // value for 'deliveryOption'
 *      deliveryService: // value for 'deliveryService'
 *      deliveryInstruction: // value for 'deliveryInstruction'
 *      deliveryMetadata: // value for 'deliveryMetadata'
 *      cutlery: // value for 'cutlery'
 *      discountCode: // value for 'discountCode'
 *      paymentMethod: // value for 'paymentMethod'
 *      address: // value for 'address'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      platform: // value for 'platform'
 *      storeOrdersAttributes: // value for 'storeOrdersAttributes'
 *   },
 * });
 */
export function useCreateMixOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateMixOrderMutation, CreateMixOrderMutationVariables>) {
        return Apollo.useMutation<CreateMixOrderMutation, CreateMixOrderMutationVariables>(CreateMixOrderDocument, baseOptions);
      }
export type CreateMixOrderMutationHookResult = ReturnType<typeof useCreateMixOrderMutation>;
export type CreateMixOrderMutationResult = Apollo.MutationResult<CreateMixOrderMutation>;
export type CreateMixOrderMutationOptions = Apollo.BaseMutationOptions<CreateMixOrderMutation, CreateMixOrderMutationVariables>;
export const CreateStoreOrderRatingDocument = gql`
    mutation CreateStoreOrderRating($storeMixOrderId: ID!, $rate: Int!, $comment: String) {
  createStoreOrderRating(
    input: {storeMixOrderId: $storeMixOrderId, rate: $rate, comment: $comment}
  ) {
    storeOrder {
      id
      rating {
        id
        rate
        comment
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateStoreOrderRatingMutationFn = Apollo.MutationFunction<CreateStoreOrderRatingMutation, CreateStoreOrderRatingMutationVariables>;

/**
 * __useCreateStoreOrderRatingMutation__
 *
 * To run a mutation, you first call `useCreateStoreOrderRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreOrderRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreOrderRatingMutation, { data, loading, error }] = useCreateStoreOrderRatingMutation({
 *   variables: {
 *      storeMixOrderId: // value for 'storeMixOrderId'
 *      rate: // value for 'rate'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateStoreOrderRatingMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreOrderRatingMutation, CreateStoreOrderRatingMutationVariables>) {
        return Apollo.useMutation<CreateStoreOrderRatingMutation, CreateStoreOrderRatingMutationVariables>(CreateStoreOrderRatingDocument, baseOptions);
      }
export type CreateStoreOrderRatingMutationHookResult = ReturnType<typeof useCreateStoreOrderRatingMutation>;
export type CreateStoreOrderRatingMutationResult = Apollo.MutationResult<CreateStoreOrderRatingMutation>;
export type CreateStoreOrderRatingMutationOptions = Apollo.BaseMutationOptions<CreateStoreOrderRatingMutation, CreateStoreOrderRatingMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($name: String, $email: String, $phoneNumber: String) {
  updateProfile(input: {name: $name, email: $email, phoneNumber: $phoneNumber}) {
    me {
      id
      name
      email
      phoneNumber
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateProfilePromptDocument = gql`
    mutation UpdateProfilePrompt($name: String!, $email: String!, $newsletterSubscribed: Boolean!) {
  updateProfilePrompt(
    name: $name
    email: $email
    newsletterSubscribed: $newsletterSubscribed
  ) {
    me {
      id
      name
      email
      phoneNumber
      newsletterSubscribed
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateProfilePromptMutationFn = Apollo.MutationFunction<UpdateProfilePromptMutation, UpdateProfilePromptMutationVariables>;

/**
 * __useUpdateProfilePromptMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePromptMutation, { data, loading, error }] = useUpdateProfilePromptMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      newsletterSubscribed: // value for 'newsletterSubscribed'
 *   },
 * });
 */
export function useUpdateProfilePromptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePromptMutation, UpdateProfilePromptMutationVariables>) {
        return Apollo.useMutation<UpdateProfilePromptMutation, UpdateProfilePromptMutationVariables>(UpdateProfilePromptDocument, baseOptions);
      }
export type UpdateProfilePromptMutationHookResult = ReturnType<typeof useUpdateProfilePromptMutation>;
export type UpdateProfilePromptMutationResult = Apollo.MutationResult<UpdateProfilePromptMutation>;
export type UpdateProfilePromptMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePromptMutation, UpdateProfilePromptMutationVariables>;
export const AccountPageDocument = gql`
    query AccountPage {
  me {
    ...Me
  }
  martMembershipsSubscribed {
    id
    name
    price
  }
  spendingTracker {
    spendRegular
    spendActual
    spendSaved
  }
}
    ${MeFragmentDoc}`;

/**
 * __useAccountPageQuery__
 *
 * To run a query within a React component, call `useAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountPageQuery(baseOptions?: Apollo.QueryHookOptions<AccountPageQuery, AccountPageQueryVariables>) {
        return Apollo.useQuery<AccountPageQuery, AccountPageQueryVariables>(AccountPageDocument, baseOptions);
      }
export function useAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPageQuery, AccountPageQueryVariables>) {
          return Apollo.useLazyQuery<AccountPageQuery, AccountPageQueryVariables>(AccountPageDocument, baseOptions);
        }
export type AccountPageQueryHookResult = ReturnType<typeof useAccountPageQuery>;
export type AccountPageLazyQueryHookResult = ReturnType<typeof useAccountPageLazyQuery>;
export type AccountPageQueryResult = Apollo.QueryResult<AccountPageQuery, AccountPageQueryVariables>;
export const UpdatePromptPageDocument = gql`
    query UpdatePromptPage {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useUpdatePromptPageQuery__
 *
 * To run a query within a React component, call `useUpdatePromptPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromptPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatePromptPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdatePromptPageQuery(baseOptions?: Apollo.QueryHookOptions<UpdatePromptPageQuery, UpdatePromptPageQueryVariables>) {
        return Apollo.useQuery<UpdatePromptPageQuery, UpdatePromptPageQueryVariables>(UpdatePromptPageDocument, baseOptions);
      }
export function useUpdatePromptPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdatePromptPageQuery, UpdatePromptPageQueryVariables>) {
          return Apollo.useLazyQuery<UpdatePromptPageQuery, UpdatePromptPageQueryVariables>(UpdatePromptPageDocument, baseOptions);
        }
export type UpdatePromptPageQueryHookResult = ReturnType<typeof useUpdatePromptPageQuery>;
export type UpdatePromptPageLazyQueryHookResult = ReturnType<typeof useUpdatePromptPageLazyQuery>;
export type UpdatePromptPageQueryResult = Apollo.QueryResult<UpdatePromptPageQuery, UpdatePromptPageQueryVariables>;
export const UpdateProfilePageDocument = gql`
    query UpdateProfilePage {
  me {
    ...Me
    countryCode
    nationalNumber
  }
}
    ${MeFragmentDoc}`;

/**
 * __useUpdateProfilePageQuery__
 *
 * To run a query within a React component, call `useUpdateProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>) {
        return Apollo.useQuery<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>(UpdateProfilePageDocument, baseOptions);
      }
export function useUpdateProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>) {
          return Apollo.useLazyQuery<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>(UpdateProfilePageDocument, baseOptions);
        }
export type UpdateProfilePageQueryHookResult = ReturnType<typeof useUpdateProfilePageQuery>;
export type UpdateProfilePageLazyQueryHookResult = ReturnType<typeof useUpdateProfilePageLazyQuery>;
export type UpdateProfilePageQueryResult = Apollo.QueryResult<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>;
export const CategoryPageDocument = gql`
    query CategoryPage($id: ID!) {
  category(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useCategoryPageQuery__
 *
 * To run a query within a React component, call `useCategoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryPageQuery(baseOptions: Apollo.QueryHookOptions<CategoryPageQuery, CategoryPageQueryVariables>) {
        return Apollo.useQuery<CategoryPageQuery, CategoryPageQueryVariables>(CategoryPageDocument, baseOptions);
      }
export function useCategoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryPageQuery, CategoryPageQueryVariables>) {
          return Apollo.useLazyQuery<CategoryPageQuery, CategoryPageQueryVariables>(CategoryPageDocument, baseOptions);
        }
export type CategoryPageQueryHookResult = ReturnType<typeof useCategoryPageQuery>;
export type CategoryPageLazyQueryHookResult = ReturnType<typeof useCategoryPageLazyQuery>;
export type CategoryPageQueryResult = Apollo.QueryResult<CategoryPageQuery, CategoryPageQueryVariables>;
export const MartCartPageDocument = gql`
    query MartCartPage($outletFriendlyPath: String!) {
  outlet(friendlyPath: $outletFriendlyPath) {
    id
    name
  }
}
    `;

/**
 * __useMartCartPageQuery__
 *
 * To run a query within a React component, call `useMartCartPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMartCartPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMartCartPageQuery({
 *   variables: {
 *      outletFriendlyPath: // value for 'outletFriendlyPath'
 *   },
 * });
 */
export function useMartCartPageQuery(baseOptions: Apollo.QueryHookOptions<MartCartPageQuery, MartCartPageQueryVariables>) {
        return Apollo.useQuery<MartCartPageQuery, MartCartPageQueryVariables>(MartCartPageDocument, baseOptions);
      }
export function useMartCartPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MartCartPageQuery, MartCartPageQueryVariables>) {
          return Apollo.useLazyQuery<MartCartPageQuery, MartCartPageQueryVariables>(MartCartPageDocument, baseOptions);
        }
export type MartCartPageQueryHookResult = ReturnType<typeof useMartCartPageQuery>;
export type MartCartPageLazyQueryHookResult = ReturnType<typeof useMartCartPageLazyQuery>;
export type MartCartPageQueryResult = Apollo.QueryResult<MartCartPageQuery, MartCartPageQueryVariables>;
export const OutletMartPageDocument = gql`
    query OutletMartPage($outletFriendlyPath: String!, $latitude: Float, $longitude: Float) {
  me {
    ...Me
  }
  outlet(friendlyPath: $outletFriendlyPath) {
    open
    close
    friendlyPath
  }
  mart(
    outletFriendlyPath: $outletFriendlyPath
    latitude: $latitude
    longitude: $longitude
  ) {
    id
    name
    friendlyPath
    banner
    active
    distance
    deliveryOption
    menuSection {
      id
      menuCategories {
        id
        name
        availableStatus
        menuItems {
          id
          name
          image
          description
          priceWithTax
          priceWithPromo
          priceWithMembership
          withPromo
          withMembership
          availableStatus
        }
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useOutletMartPageQuery__
 *
 * To run a query within a React component, call `useOutletMartPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletMartPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletMartPageQuery({
 *   variables: {
 *      outletFriendlyPath: // value for 'outletFriendlyPath'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useOutletMartPageQuery(baseOptions: Apollo.QueryHookOptions<OutletMartPageQuery, OutletMartPageQueryVariables>) {
        return Apollo.useQuery<OutletMartPageQuery, OutletMartPageQueryVariables>(OutletMartPageDocument, baseOptions);
      }
export function useOutletMartPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletMartPageQuery, OutletMartPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletMartPageQuery, OutletMartPageQueryVariables>(OutletMartPageDocument, baseOptions);
        }
export type OutletMartPageQueryHookResult = ReturnType<typeof useOutletMartPageQuery>;
export type OutletMartPageLazyQueryHookResult = ReturnType<typeof useOutletMartPageLazyQuery>;
export type OutletMartPageQueryResult = Apollo.QueryResult<OutletMartPageQuery, OutletMartPageQueryVariables>;
export const OutletMartMenuItemPageDocument = gql`
    query OutletMartMenuItemPage($id: ID!) {
  me {
    ...Me
  }
  menuItem(id: $id) {
    id
    name
    description
    priceWithTax
    priceWithPromo
    priceWithMembership
    withPromo
    withMembership
    image
    availableStatus
    store {
      id
      active
    }
    menuCategories {
      id
      availableStatus
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useOutletMartMenuItemPageQuery__
 *
 * To run a query within a React component, call `useOutletMartMenuItemPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletMartMenuItemPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletMartMenuItemPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutletMartMenuItemPageQuery(baseOptions: Apollo.QueryHookOptions<OutletMartMenuItemPageQuery, OutletMartMenuItemPageQueryVariables>) {
        return Apollo.useQuery<OutletMartMenuItemPageQuery, OutletMartMenuItemPageQueryVariables>(OutletMartMenuItemPageDocument, baseOptions);
      }
export function useOutletMartMenuItemPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletMartMenuItemPageQuery, OutletMartMenuItemPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletMartMenuItemPageQuery, OutletMartMenuItemPageQueryVariables>(OutletMartMenuItemPageDocument, baseOptions);
        }
export type OutletMartMenuItemPageQueryHookResult = ReturnType<typeof useOutletMartMenuItemPageQuery>;
export type OutletMartMenuItemPageLazyQueryHookResult = ReturnType<typeof useOutletMartMenuItemPageLazyQuery>;
export type OutletMartMenuItemPageQueryResult = Apollo.QueryResult<OutletMartMenuItemPageQuery, OutletMartMenuItemPageQueryVariables>;
export const MartPageDocument = gql`
    query MartPage($latitude: Float, $longitude: Float) {
  me {
    ...Me
  }
  outletsWithMart(latitude: $latitude, longitude: $longitude) {
    id
    name
    image
    open
    close
    friendlyPath
    distance
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMartPageQuery__
 *
 * To run a query within a React component, call `useMartPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMartPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMartPageQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useMartPageQuery(baseOptions?: Apollo.QueryHookOptions<MartPageQuery, MartPageQueryVariables>) {
        return Apollo.useQuery<MartPageQuery, MartPageQueryVariables>(MartPageDocument, baseOptions);
      }
export function useMartPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MartPageQuery, MartPageQueryVariables>) {
          return Apollo.useLazyQuery<MartPageQuery, MartPageQueryVariables>(MartPageDocument, baseOptions);
        }
export type MartPageQueryHookResult = ReturnType<typeof useMartPageQuery>;
export type MartPageLazyQueryHookResult = ReturnType<typeof useMartPageLazyQuery>;
export type MartPageQueryResult = Apollo.QueryResult<MartPageQuery, MartPageQueryVariables>;
export const MartMembershipPageDocument = gql`
    query MartMembershipPage {
  me {
    ...Me
  }
  martMembershipsPlan {
    id
    name
    price
  }
  spendingTracker {
    spendRegular
    spendActual
    spendSaved
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMartMembershipPageQuery__
 *
 * To run a query within a React component, call `useMartMembershipPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMartMembershipPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMartMembershipPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMartMembershipPageQuery(baseOptions?: Apollo.QueryHookOptions<MartMembershipPageQuery, MartMembershipPageQueryVariables>) {
        return Apollo.useQuery<MartMembershipPageQuery, MartMembershipPageQueryVariables>(MartMembershipPageDocument, baseOptions);
      }
export function useMartMembershipPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MartMembershipPageQuery, MartMembershipPageQueryVariables>) {
          return Apollo.useLazyQuery<MartMembershipPageQuery, MartMembershipPageQueryVariables>(MartMembershipPageDocument, baseOptions);
        }
export type MartMembershipPageQueryHookResult = ReturnType<typeof useMartMembershipPageQuery>;
export type MartMembershipPageLazyQueryHookResult = ReturnType<typeof useMartMembershipPageLazyQuery>;
export type MartMembershipPageQueryResult = Apollo.QueryResult<MartMembershipPageQuery, MartMembershipPageQueryVariables>;
export const MixOrderPageDocument = gql`
    query MixOrderPage {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMixOrderPageQuery__
 *
 * To run a query within a React component, call `useMixOrderPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixOrderPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixOrderPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixOrderPageQuery(baseOptions?: Apollo.QueryHookOptions<MixOrderPageQuery, MixOrderPageQueryVariables>) {
        return Apollo.useQuery<MixOrderPageQuery, MixOrderPageQueryVariables>(MixOrderPageDocument, baseOptions);
      }
export function useMixOrderPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixOrderPageQuery, MixOrderPageQueryVariables>) {
          return Apollo.useLazyQuery<MixOrderPageQuery, MixOrderPageQueryVariables>(MixOrderPageDocument, baseOptions);
        }
export type MixOrderPageQueryHookResult = ReturnType<typeof useMixOrderPageQuery>;
export type MixOrderPageLazyQueryHookResult = ReturnType<typeof useMixOrderPageLazyQuery>;
export type MixOrderPageQueryResult = Apollo.QueryResult<MixOrderPageQuery, MixOrderPageQueryVariables>;
export const MixOrdersPageDocument = gql`
    query MixOrdersPage {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMixOrdersPageQuery__
 *
 * To run a query within a React component, call `useMixOrdersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixOrdersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixOrdersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixOrdersPageQuery(baseOptions?: Apollo.QueryHookOptions<MixOrdersPageQuery, MixOrdersPageQueryVariables>) {
        return Apollo.useQuery<MixOrdersPageQuery, MixOrdersPageQueryVariables>(MixOrdersPageDocument, baseOptions);
      }
export function useMixOrdersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixOrdersPageQuery, MixOrdersPageQueryVariables>) {
          return Apollo.useLazyQuery<MixOrdersPageQuery, MixOrdersPageQueryVariables>(MixOrdersPageDocument, baseOptions);
        }
export type MixOrdersPageQueryHookResult = ReturnType<typeof useMixOrdersPageQuery>;
export type MixOrdersPageLazyQueryHookResult = ReturnType<typeof useMixOrdersPageLazyQuery>;
export type MixOrdersPageQueryResult = Apollo.QueryResult<MixOrdersPageQuery, MixOrdersPageQueryVariables>;
export const OutletStorePageDocument = gql`
    query OutletStorePage($friendlyPath: String!) {
  me {
    ...Me
  }
  store(friendlyPath: $friendlyPath) {
    id
    name
    country
    friendlyPath
    banner
    deliveryOption
    deliveryServices
    taxPercentage
    active
    menuSection {
      id
      menuCategories {
        id
        name
        availableStatus
        menuItems {
          id
          name
          description
          priceWithTax
          priceWithPromo
          withPromo
          image
          availableStatus
        }
      }
    }
    outlet {
      id
      name
      friendlyPath
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useOutletStorePageQuery__
 *
 * To run a query within a React component, call `useOutletStorePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletStorePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletStorePageQuery({
 *   variables: {
 *      friendlyPath: // value for 'friendlyPath'
 *   },
 * });
 */
export function useOutletStorePageQuery(baseOptions: Apollo.QueryHookOptions<OutletStorePageQuery, OutletStorePageQueryVariables>) {
        return Apollo.useQuery<OutletStorePageQuery, OutletStorePageQueryVariables>(OutletStorePageDocument, baseOptions);
      }
export function useOutletStorePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletStorePageQuery, OutletStorePageQueryVariables>) {
          return Apollo.useLazyQuery<OutletStorePageQuery, OutletStorePageQueryVariables>(OutletStorePageDocument, baseOptions);
        }
export type OutletStorePageQueryHookResult = ReturnType<typeof useOutletStorePageQuery>;
export type OutletStorePageLazyQueryHookResult = ReturnType<typeof useOutletStorePageLazyQuery>;
export type OutletStorePageQueryResult = Apollo.QueryResult<OutletStorePageQuery, OutletStorePageQueryVariables>;
export const OutletCartPageDocument = gql`
    query OutletCartPage($friendlyPath: String!) {
  outlet(friendlyPath: $friendlyPath) {
    id
    name
  }
}
    `;

/**
 * __useOutletCartPageQuery__
 *
 * To run a query within a React component, call `useOutletCartPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletCartPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletCartPageQuery({
 *   variables: {
 *      friendlyPath: // value for 'friendlyPath'
 *   },
 * });
 */
export function useOutletCartPageQuery(baseOptions: Apollo.QueryHookOptions<OutletCartPageQuery, OutletCartPageQueryVariables>) {
        return Apollo.useQuery<OutletCartPageQuery, OutletCartPageQueryVariables>(OutletCartPageDocument, baseOptions);
      }
export function useOutletCartPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletCartPageQuery, OutletCartPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletCartPageQuery, OutletCartPageQueryVariables>(OutletCartPageDocument, baseOptions);
        }
export type OutletCartPageQueryHookResult = ReturnType<typeof useOutletCartPageQuery>;
export type OutletCartPageLazyQueryHookResult = ReturnType<typeof useOutletCartPageLazyQuery>;
export type OutletCartPageQueryResult = Apollo.QueryResult<OutletCartPageQuery, OutletCartPageQueryVariables>;
export const OutletPageDocument = gql`
    query OutletPage($friendlyPath: String!) {
  me {
    ...Me
  }
  outlet(friendlyPath: $friendlyPath) {
    id
    name
    noticeImage {
      ...NoticeImage
    }
    image
    slides
    slidesUrls
  }
}
    ${MeFragmentDoc}
${NoticeImageFragmentDoc}`;

/**
 * __useOutletPageQuery__
 *
 * To run a query within a React component, call `useOutletPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletPageQuery({
 *   variables: {
 *      friendlyPath: // value for 'friendlyPath'
 *   },
 * });
 */
export function useOutletPageQuery(baseOptions: Apollo.QueryHookOptions<OutletPageQuery, OutletPageQueryVariables>) {
        return Apollo.useQuery<OutletPageQuery, OutletPageQueryVariables>(OutletPageDocument, baseOptions);
      }
export function useOutletPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletPageQuery, OutletPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletPageQuery, OutletPageQueryVariables>(OutletPageDocument, baseOptions);
        }
export type OutletPageQueryHookResult = ReturnType<typeof useOutletPageQuery>;
export type OutletPageLazyQueryHookResult = ReturnType<typeof useOutletPageLazyQuery>;
export type OutletPageQueryResult = Apollo.QueryResult<OutletPageQuery, OutletPageQueryVariables>;
export const OutletStoreMenuItemPageDocument = gql`
    query OutletStoreMenuItemPage($id: ID!) {
  me {
    ...Me
  }
  menuItem(id: $id) {
    id
    name
    description
    priceWithTax
    priceWithPromo
    withPromo
    image
    availableStatus
    store {
      id
      name
      country
      friendlyPath
      active
      deliveryOption
      outlet {
        id
      }
    }
    menuCategories {
      id
      availableStatus
    }
    menuOptions {
      id
      name
      min
      max
      availableStatus
      itemOptions {
        id
        name
        priceWithTax
        availableStatus
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useOutletStoreMenuItemPageQuery__
 *
 * To run a query within a React component, call `useOutletStoreMenuItemPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletStoreMenuItemPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletStoreMenuItemPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutletStoreMenuItemPageQuery(baseOptions: Apollo.QueryHookOptions<OutletStoreMenuItemPageQuery, OutletStoreMenuItemPageQueryVariables>) {
        return Apollo.useQuery<OutletStoreMenuItemPageQuery, OutletStoreMenuItemPageQueryVariables>(OutletStoreMenuItemPageDocument, baseOptions);
      }
export function useOutletStoreMenuItemPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletStoreMenuItemPageQuery, OutletStoreMenuItemPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletStoreMenuItemPageQuery, OutletStoreMenuItemPageQueryVariables>(OutletStoreMenuItemPageDocument, baseOptions);
        }
export type OutletStoreMenuItemPageQueryHookResult = ReturnType<typeof useOutletStoreMenuItemPageQuery>;
export type OutletStoreMenuItemPageLazyQueryHookResult = ReturnType<typeof useOutletStoreMenuItemPageLazyQuery>;
export type OutletStoreMenuItemPageQueryResult = Apollo.QueryResult<OutletStoreMenuItemPageQuery, OutletStoreMenuItemPageQueryVariables>;
export const SearchPageDocument = gql`
    query SearchPage {
  categories {
    id
    name
    popular
  }
}
    `;

/**
 * __useSearchPageQuery__
 *
 * To run a query within a React component, call `useSearchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchPageQuery(baseOptions?: Apollo.QueryHookOptions<SearchPageQuery, SearchPageQueryVariables>) {
        return Apollo.useQuery<SearchPageQuery, SearchPageQueryVariables>(SearchPageDocument, baseOptions);
      }
export function useSearchPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPageQuery, SearchPageQueryVariables>) {
          return Apollo.useLazyQuery<SearchPageQuery, SearchPageQueryVariables>(SearchPageDocument, baseOptions);
        }
export type SearchPageQueryHookResult = ReturnType<typeof useSearchPageQuery>;
export type SearchPageLazyQueryHookResult = ReturnType<typeof useSearchPageLazyQuery>;
export type SearchPageQueryResult = Apollo.QueryResult<SearchPageQuery, SearchPageQueryVariables>;
export const AppMetadataDocument = gql`
    query AppMetadata {
  app {
    id
    consumerCountry
    consumerCountryCode
    operatingCountries {
      id
      country
      code
      currency {
        id
        symbol
        code
        paymentMethods
      }
    }
    mixinPromo {
      id
      outletIds
      storesCount
      amount
      category
      minSpend
      capped
    }
  }
}
    `;

/**
 * __useAppMetadataQuery__
 *
 * To run a query within a React component, call `useAppMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppMetadataQuery(baseOptions?: Apollo.QueryHookOptions<AppMetadataQuery, AppMetadataQueryVariables>) {
        return Apollo.useQuery<AppMetadataQuery, AppMetadataQueryVariables>(AppMetadataDocument, baseOptions);
      }
export function useAppMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppMetadataQuery, AppMetadataQueryVariables>) {
          return Apollo.useLazyQuery<AppMetadataQuery, AppMetadataQueryVariables>(AppMetadataDocument, baseOptions);
        }
export type AppMetadataQueryHookResult = ReturnType<typeof useAppMetadataQuery>;
export type AppMetadataLazyQueryHookResult = ReturnType<typeof useAppMetadataLazyQuery>;
export type AppMetadataQueryResult = Apollo.QueryResult<AppMetadataQuery, AppMetadataQueryVariables>;
export const AnnouncementDocument = gql`
    query Announcement {
  announcement
}
    `;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementQuery(baseOptions?: Apollo.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
        return Apollo.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, baseOptions);
      }
export function useAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
          return Apollo.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, baseOptions);
        }
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = Apollo.QueryResult<AnnouncementQuery, AnnouncementQueryVariables>;
export const ApplyMixDiscountCodeDocument = gql`
    query ApplyMixDiscountCode($discountCode: String!, $subtotal: Float!, $storeData: [MixDiscountStore!]!, $deliveryOption: DeliveryOption!, $paymentMethod: PaymentMethod) {
  applyMixDiscountCode(
    discountCode: $discountCode
    subtotal: $subtotal
    storeData: $storeData
    deliveryOption: $deliveryOption
    paymentMethod: $paymentMethod
  ) {
    id
    success
    message
    discounts {
      id
      appliedAmount
      error
    }
  }
}
    `;

/**
 * __useApplyMixDiscountCodeQuery__
 *
 * To run a query within a React component, call `useApplyMixDiscountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplyMixDiscountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplyMixDiscountCodeQuery({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *      subtotal: // value for 'subtotal'
 *      storeData: // value for 'storeData'
 *      deliveryOption: // value for 'deliveryOption'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useApplyMixDiscountCodeQuery(baseOptions: Apollo.QueryHookOptions<ApplyMixDiscountCodeQuery, ApplyMixDiscountCodeQueryVariables>) {
        return Apollo.useQuery<ApplyMixDiscountCodeQuery, ApplyMixDiscountCodeQueryVariables>(ApplyMixDiscountCodeDocument, baseOptions);
      }
export function useApplyMixDiscountCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplyMixDiscountCodeQuery, ApplyMixDiscountCodeQueryVariables>) {
          return Apollo.useLazyQuery<ApplyMixDiscountCodeQuery, ApplyMixDiscountCodeQueryVariables>(ApplyMixDiscountCodeDocument, baseOptions);
        }
export type ApplyMixDiscountCodeQueryHookResult = ReturnType<typeof useApplyMixDiscountCodeQuery>;
export type ApplyMixDiscountCodeLazyQueryHookResult = ReturnType<typeof useApplyMixDiscountCodeLazyQuery>;
export type ApplyMixDiscountCodeQueryResult = Apollo.QueryResult<ApplyMixDiscountCodeQuery, ApplyMixDiscountCodeQueryVariables>;
export const GetOutletsWithMartDocument = gql`
    query GetOutletsWithMart($latitude: Float, $longitude: Float) {
  outletsWithMart(latitude: $latitude, longitude: $longitude) {
    id
    name
    image
    open
    close
    friendlyPath
    distance
  }
}
    `;

/**
 * __useGetOutletsWithMartQuery__
 *
 * To run a query within a React component, call `useGetOutletsWithMartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletsWithMartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletsWithMartQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useGetOutletsWithMartQuery(baseOptions?: Apollo.QueryHookOptions<GetOutletsWithMartQuery, GetOutletsWithMartQueryVariables>) {
        return Apollo.useQuery<GetOutletsWithMartQuery, GetOutletsWithMartQueryVariables>(GetOutletsWithMartDocument, baseOptions);
      }
export function useGetOutletsWithMartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutletsWithMartQuery, GetOutletsWithMartQueryVariables>) {
          return Apollo.useLazyQuery<GetOutletsWithMartQuery, GetOutletsWithMartQueryVariables>(GetOutletsWithMartDocument, baseOptions);
        }
export type GetOutletsWithMartQueryHookResult = ReturnType<typeof useGetOutletsWithMartQuery>;
export type GetOutletsWithMartLazyQueryHookResult = ReturnType<typeof useGetOutletsWithMartLazyQuery>;
export type GetOutletsWithMartQueryResult = Apollo.QueryResult<GetOutletsWithMartQuery, GetOutletsWithMartQueryVariables>;
export const GetMartDocument = gql`
    query GetMart($outletFriendlyPath: String!, $latitude: Float, $longitude: Float) {
  mart(
    outletFriendlyPath: $outletFriendlyPath
    latitude: $latitude
    longitude: $longitude
  ) {
    id
    name
    friendlyPath
    address
    distance
    phoneNumber
    deliveryOption
    banner
    active
    menuSection {
      id
      menuCategories {
        id
        name
        availableStatus
        menuItems {
          id
          name
          image
          description
          priceWithTax
          priceWithPromo
          priceWithMembership
          withPromo
          withMembership
          availableStatus
        }
      }
    }
  }
}
    `;

/**
 * __useGetMartQuery__
 *
 * To run a query within a React component, call `useGetMartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMartQuery({
 *   variables: {
 *      outletFriendlyPath: // value for 'outletFriendlyPath'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useGetMartQuery(baseOptions: Apollo.QueryHookOptions<GetMartQuery, GetMartQueryVariables>) {
        return Apollo.useQuery<GetMartQuery, GetMartQueryVariables>(GetMartDocument, baseOptions);
      }
export function useGetMartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMartQuery, GetMartQueryVariables>) {
          return Apollo.useLazyQuery<GetMartQuery, GetMartQueryVariables>(GetMartDocument, baseOptions);
        }
export type GetMartQueryHookResult = ReturnType<typeof useGetMartQuery>;
export type GetMartLazyQueryHookResult = ReturnType<typeof useGetMartLazyQuery>;
export type GetMartQueryResult = Apollo.QueryResult<GetMartQuery, GetMartQueryVariables>;
export const GetMartSearchDocument = gql`
    query GetMartSearch($searchString: String!, $storeFriendlyPath: String!) {
  martSearch(q: $searchString, storeFriendlyPath: $storeFriendlyPath) {
    id
    name
    image
    description
    priceWithTax
    priceWithPromo
    priceWithMembership
    withPromo
    withMembership
    availableStatus
    store {
      id
    }
  }
}
    `;

/**
 * __useGetMartSearchQuery__
 *
 * To run a query within a React component, call `useGetMartSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMartSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMartSearchQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      storeFriendlyPath: // value for 'storeFriendlyPath'
 *   },
 * });
 */
export function useGetMartSearchQuery(baseOptions: Apollo.QueryHookOptions<GetMartSearchQuery, GetMartSearchQueryVariables>) {
        return Apollo.useQuery<GetMartSearchQuery, GetMartSearchQueryVariables>(GetMartSearchDocument, baseOptions);
      }
export function useGetMartSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMartSearchQuery, GetMartSearchQueryVariables>) {
          return Apollo.useLazyQuery<GetMartSearchQuery, GetMartSearchQueryVariables>(GetMartSearchDocument, baseOptions);
        }
export type GetMartSearchQueryHookResult = ReturnType<typeof useGetMartSearchQuery>;
export type GetMartSearchLazyQueryHookResult = ReturnType<typeof useGetMartSearchLazyQuery>;
export type GetMartSearchQueryResult = Apollo.QueryResult<GetMartSearchQuery, GetMartSearchQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMembershipDocument = gql`
    query GetMembership {
  martMembershipsPlan {
    id
    name
    price
  }
}
    `;

/**
 * __useGetMembershipQuery__
 *
 * To run a query within a React component, call `useGetMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembershipQuery(baseOptions?: Apollo.QueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
        return Apollo.useQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, baseOptions);
      }
export function useGetMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
          return Apollo.useLazyQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, baseOptions);
        }
export type GetMembershipQueryHookResult = ReturnType<typeof useGetMembershipQuery>;
export type GetMembershipLazyQueryHookResult = ReturnType<typeof useGetMembershipLazyQuery>;
export type GetMembershipQueryResult = Apollo.QueryResult<GetMembershipQuery, GetMembershipQueryVariables>;
export const GetSubscribedMembershipDocument = gql`
    query GetSubscribedMembership {
  martMembershipsSubscribed {
    id
    name
    price
  }
}
    `;

/**
 * __useGetSubscribedMembershipQuery__
 *
 * To run a query within a React component, call `useGetSubscribedMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribedMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribedMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscribedMembershipQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscribedMembershipQuery, GetSubscribedMembershipQueryVariables>) {
        return Apollo.useQuery<GetSubscribedMembershipQuery, GetSubscribedMembershipQueryVariables>(GetSubscribedMembershipDocument, baseOptions);
      }
export function useGetSubscribedMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscribedMembershipQuery, GetSubscribedMembershipQueryVariables>) {
          return Apollo.useLazyQuery<GetSubscribedMembershipQuery, GetSubscribedMembershipQueryVariables>(GetSubscribedMembershipDocument, baseOptions);
        }
export type GetSubscribedMembershipQueryHookResult = ReturnType<typeof useGetSubscribedMembershipQuery>;
export type GetSubscribedMembershipLazyQueryHookResult = ReturnType<typeof useGetSubscribedMembershipLazyQuery>;
export type GetSubscribedMembershipQueryResult = Apollo.QueryResult<GetSubscribedMembershipQuery, GetSubscribedMembershipQueryVariables>;
export const GetSpendingDocument = gql`
    query GetSpending {
  spendingTracker {
    spendActual
    spendRegular
    spendSaved
  }
}
    `;

/**
 * __useGetSpendingQuery__
 *
 * To run a query within a React component, call `useGetSpendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpendingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpendingQuery(baseOptions?: Apollo.QueryHookOptions<GetSpendingQuery, GetSpendingQueryVariables>) {
        return Apollo.useQuery<GetSpendingQuery, GetSpendingQueryVariables>(GetSpendingDocument, baseOptions);
      }
export function useGetSpendingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpendingQuery, GetSpendingQueryVariables>) {
          return Apollo.useLazyQuery<GetSpendingQuery, GetSpendingQueryVariables>(GetSpendingDocument, baseOptions);
        }
export type GetSpendingQueryHookResult = ReturnType<typeof useGetSpendingQuery>;
export type GetSpendingLazyQueryHookResult = ReturnType<typeof useGetSpendingLazyQuery>;
export type GetSpendingQueryResult = Apollo.QueryResult<GetSpendingQuery, GetSpendingQueryVariables>;
export const GetMenuItemsDocument = gql`
    query GetMenuItems($ids: [ID!]!) {
  menuItems(ids: $ids) {
    id
    name
    availableStatus
  }
}
    `;

/**
 * __useGetMenuItemsQuery__
 *
 * To run a query within a React component, call `useGetMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMenuItemsQuery(baseOptions: Apollo.QueryHookOptions<GetMenuItemsQuery, GetMenuItemsQueryVariables>) {
        return Apollo.useQuery<GetMenuItemsQuery, GetMenuItemsQueryVariables>(GetMenuItemsDocument, baseOptions);
      }
export function useGetMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuItemsQuery, GetMenuItemsQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuItemsQuery, GetMenuItemsQueryVariables>(GetMenuItemsDocument, baseOptions);
        }
export type GetMenuItemsQueryHookResult = ReturnType<typeof useGetMenuItemsQuery>;
export type GetMenuItemsLazyQueryHookResult = ReturnType<typeof useGetMenuItemsLazyQuery>;
export type GetMenuItemsQueryResult = Apollo.QueryResult<GetMenuItemsQuery, GetMenuItemsQueryVariables>;
export const GetMenuItemDocument = gql`
    query GetMenuItem($id: ID!) {
  menuItem(id: $id) {
    id
    menuOptions {
      id
      name
      min
      max
      availableStatus
      itemOptions {
        id
        name
        priceWithTax
        availableStatus
      }
    }
  }
}
    `;

/**
 * __useGetMenuItemQuery__
 *
 * To run a query within a React component, call `useGetMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMenuItemQuery(baseOptions: Apollo.QueryHookOptions<GetMenuItemQuery, GetMenuItemQueryVariables>) {
        return Apollo.useQuery<GetMenuItemQuery, GetMenuItemQueryVariables>(GetMenuItemDocument, baseOptions);
      }
export function useGetMenuItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuItemQuery, GetMenuItemQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuItemQuery, GetMenuItemQueryVariables>(GetMenuItemDocument, baseOptions);
        }
export type GetMenuItemQueryHookResult = ReturnType<typeof useGetMenuItemQuery>;
export type GetMenuItemLazyQueryHookResult = ReturnType<typeof useGetMenuItemLazyQuery>;
export type GetMenuItemQueryResult = Apollo.QueryResult<GetMenuItemQuery, GetMenuItemQueryVariables>;
export const GetProductItemDocument = gql`
    query GetProductItem($id: ID!) {
  menuItem(id: $id) {
    id
    name
    image
    description
    priceWithTax
    priceWithPromo
    priceWithMembership
    withPromo
    withMembership
  }
}
    `;

/**
 * __useGetProductItemQuery__
 *
 * To run a query within a React component, call `useGetProductItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductItemQuery(baseOptions: Apollo.QueryHookOptions<GetProductItemQuery, GetProductItemQueryVariables>) {
        return Apollo.useQuery<GetProductItemQuery, GetProductItemQueryVariables>(GetProductItemDocument, baseOptions);
      }
export function useGetProductItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductItemQuery, GetProductItemQueryVariables>) {
          return Apollo.useLazyQuery<GetProductItemQuery, GetProductItemQueryVariables>(GetProductItemDocument, baseOptions);
        }
export type GetProductItemQueryHookResult = ReturnType<typeof useGetProductItemQuery>;
export type GetProductItemLazyQueryHookResult = ReturnType<typeof useGetProductItemLazyQuery>;
export type GetProductItemQueryResult = Apollo.QueryResult<GetProductItemQuery, GetProductItemQueryVariables>;
export const GetMixOrdersDocument = gql`
    query GetMixOrders($variant: StoreVariant, $states: [OrderState!]!, $limit: Int!, $offset: Int!) {
  mixOrders(
    variant: $variant
    states: $states
    offsetAttributes: {limit: $limit, offset: $offset}
  ) {
    id
    state
    country
    orderCode
    deliveryOption
    finalPrice
    acceptedTime
    paymentMethod
    store {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMixOrdersQuery__
 *
 * To run a query within a React component, call `useGetMixOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMixOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMixOrdersQuery({
 *   variables: {
 *      variant: // value for 'variant'
 *      states: // value for 'states'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMixOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetMixOrdersQuery, GetMixOrdersQueryVariables>) {
        return Apollo.useQuery<GetMixOrdersQuery, GetMixOrdersQueryVariables>(GetMixOrdersDocument, baseOptions);
      }
export function useGetMixOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMixOrdersQuery, GetMixOrdersQueryVariables>) {
          return Apollo.useLazyQuery<GetMixOrdersQuery, GetMixOrdersQueryVariables>(GetMixOrdersDocument, baseOptions);
        }
export type GetMixOrdersQueryHookResult = ReturnType<typeof useGetMixOrdersQuery>;
export type GetMixOrdersLazyQueryHookResult = ReturnType<typeof useGetMixOrdersLazyQuery>;
export type GetMixOrdersQueryResult = Apollo.QueryResult<GetMixOrdersQuery, GetMixOrdersQueryVariables>;
export const GetOngoingMixOrdersDocument = gql`
    query GetOngoingMixOrders($states: [OrderState!]!, $limit: Int!, $offset: Int!) {
  mixOrders(states: $states, offsetAttributes: {limit: $limit, offset: $offset}) {
    id
    orderCode
    acceptedTime
    finalPrice
    deliveryOption
    state
    paymentMethod
    store {
      id
      name
    }
    deliveryBooking {
      id
      eta
    }
  }
}
    `;

/**
 * __useGetOngoingMixOrdersQuery__
 *
 * To run a query within a React component, call `useGetOngoingMixOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOngoingMixOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOngoingMixOrdersQuery({
 *   variables: {
 *      states: // value for 'states'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOngoingMixOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOngoingMixOrdersQuery, GetOngoingMixOrdersQueryVariables>) {
        return Apollo.useQuery<GetOngoingMixOrdersQuery, GetOngoingMixOrdersQueryVariables>(GetOngoingMixOrdersDocument, baseOptions);
      }
export function useGetOngoingMixOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOngoingMixOrdersQuery, GetOngoingMixOrdersQueryVariables>) {
          return Apollo.useLazyQuery<GetOngoingMixOrdersQuery, GetOngoingMixOrdersQueryVariables>(GetOngoingMixOrdersDocument, baseOptions);
        }
export type GetOngoingMixOrdersQueryHookResult = ReturnType<typeof useGetOngoingMixOrdersQuery>;
export type GetOngoingMixOrdersLazyQueryHookResult = ReturnType<typeof useGetOngoingMixOrdersLazyQuery>;
export type GetOngoingMixOrdersQueryResult = Apollo.QueryResult<GetOngoingMixOrdersQuery, GetOngoingMixOrdersQueryVariables>;
export const GetMixOrderDocument = gql`
    query GetMixOrder($id: ID!) {
  mixOrder(id: $id) {
    id
    state
    country
    orderId
    orderCode
    acceptedTime
    paymentMethod
    deliveryOption
    cutlery
    discountCode
    originalPrice
    tax
    deliveryFee
    promo
    finalPrice
    outlet {
      id
      name
      address
    }
    deliveryBooking {
      id
      integration
      eta
    }
    storeOrders {
      id
      state
      cancelMessage
      cabinetSlotNumber
      store {
        id
        name
        phoneNumber
      }
      rating {
        id
        rate
        comment
      }
      orderMenuItemsWithCount {
        id
        count
        item {
          id
          name
          price
          remark
          details {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMixOrderQuery__
 *
 * To run a query within a React component, call `useGetMixOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMixOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMixOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMixOrderQuery(baseOptions: Apollo.QueryHookOptions<GetMixOrderQuery, GetMixOrderQueryVariables>) {
        return Apollo.useQuery<GetMixOrderQuery, GetMixOrderQueryVariables>(GetMixOrderDocument, baseOptions);
      }
export function useGetMixOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMixOrderQuery, GetMixOrderQueryVariables>) {
          return Apollo.useLazyQuery<GetMixOrderQuery, GetMixOrderQueryVariables>(GetMixOrderDocument, baseOptions);
        }
export type GetMixOrderQueryHookResult = ReturnType<typeof useGetMixOrderQuery>;
export type GetMixOrderLazyQueryHookResult = ReturnType<typeof useGetMixOrderLazyQuery>;
export type GetMixOrderQueryResult = Apollo.QueryResult<GetMixOrderQuery, GetMixOrderQueryVariables>;
export const GetMixOrderDeliveryDetailsDocument = gql`
    query GetMixOrderDeliveryDetails($mixOrderId: ID!) {
  mixOrder(id: $mixOrderId) {
    id
    state
    address
    store {
      id
      address
    }
    deliveryBooking {
      id
      bookingId
      instructions
      integration
      riderName
      riderPhoneNumber
      riderVehicleNo
      trackingLink
    }
  }
}
    `;

/**
 * __useGetMixOrderDeliveryDetailsQuery__
 *
 * To run a query within a React component, call `useGetMixOrderDeliveryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMixOrderDeliveryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMixOrderDeliveryDetailsQuery({
 *   variables: {
 *      mixOrderId: // value for 'mixOrderId'
 *   },
 * });
 */
export function useGetMixOrderDeliveryDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetMixOrderDeliveryDetailsQuery, GetMixOrderDeliveryDetailsQueryVariables>) {
        return Apollo.useQuery<GetMixOrderDeliveryDetailsQuery, GetMixOrderDeliveryDetailsQueryVariables>(GetMixOrderDeliveryDetailsDocument, baseOptions);
      }
export function useGetMixOrderDeliveryDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMixOrderDeliveryDetailsQuery, GetMixOrderDeliveryDetailsQueryVariables>) {
          return Apollo.useLazyQuery<GetMixOrderDeliveryDetailsQuery, GetMixOrderDeliveryDetailsQueryVariables>(GetMixOrderDeliveryDetailsDocument, baseOptions);
        }
export type GetMixOrderDeliveryDetailsQueryHookResult = ReturnType<typeof useGetMixOrderDeliveryDetailsQuery>;
export type GetMixOrderDeliveryDetailsLazyQueryHookResult = ReturnType<typeof useGetMixOrderDeliveryDetailsLazyQuery>;
export type GetMixOrderDeliveryDetailsQueryResult = Apollo.QueryResult<GetMixOrderDeliveryDetailsQuery, GetMixOrderDeliveryDetailsQueryVariables>;
export const GetMixOrderRiderCoordinatesDocument = gql`
    query GetMixOrderRiderCoordinates($mixOrderId: ID!) {
  mixOrder(id: $mixOrderId) {
    id
    state
    latitude
    longitude
    deliveryBooking {
      id
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useGetMixOrderRiderCoordinatesQuery__
 *
 * To run a query within a React component, call `useGetMixOrderRiderCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMixOrderRiderCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMixOrderRiderCoordinatesQuery({
 *   variables: {
 *      mixOrderId: // value for 'mixOrderId'
 *   },
 * });
 */
export function useGetMixOrderRiderCoordinatesQuery(baseOptions: Apollo.QueryHookOptions<GetMixOrderRiderCoordinatesQuery, GetMixOrderRiderCoordinatesQueryVariables>) {
        return Apollo.useQuery<GetMixOrderRiderCoordinatesQuery, GetMixOrderRiderCoordinatesQueryVariables>(GetMixOrderRiderCoordinatesDocument, baseOptions);
      }
export function useGetMixOrderRiderCoordinatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMixOrderRiderCoordinatesQuery, GetMixOrderRiderCoordinatesQueryVariables>) {
          return Apollo.useLazyQuery<GetMixOrderRiderCoordinatesQuery, GetMixOrderRiderCoordinatesQueryVariables>(GetMixOrderRiderCoordinatesDocument, baseOptions);
        }
export type GetMixOrderRiderCoordinatesQueryHookResult = ReturnType<typeof useGetMixOrderRiderCoordinatesQuery>;
export type GetMixOrderRiderCoordinatesLazyQueryHookResult = ReturnType<typeof useGetMixOrderRiderCoordinatesLazyQuery>;
export type GetMixOrderRiderCoordinatesQueryResult = Apollo.QueryResult<GetMixOrderRiderCoordinatesQuery, GetMixOrderRiderCoordinatesQueryVariables>;
export const GetOrderReceiptDocument = gql`
    query GetOrderReceipt($id: ID!) {
  orderReceipt(id: $id)
}
    `;

/**
 * __useGetOrderReceiptQuery__
 *
 * To run a query within a React component, call `useGetOrderReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetOrderReceiptQuery, GetOrderReceiptQueryVariables>) {
        return Apollo.useQuery<GetOrderReceiptQuery, GetOrderReceiptQueryVariables>(GetOrderReceiptDocument, baseOptions);
      }
export function useGetOrderReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderReceiptQuery, GetOrderReceiptQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderReceiptQuery, GetOrderReceiptQueryVariables>(GetOrderReceiptDocument, baseOptions);
        }
export type GetOrderReceiptQueryHookResult = ReturnType<typeof useGetOrderReceiptQuery>;
export type GetOrderReceiptLazyQueryHookResult = ReturnType<typeof useGetOrderReceiptLazyQuery>;
export type GetOrderReceiptQueryResult = Apollo.QueryResult<GetOrderReceiptQuery, GetOrderReceiptQueryVariables>;
export const GetOrdersToRateDocument = gql`
    query GetOrdersToRate {
  ordersToRate {
    id
    mixOrder {
      acceptedTime
      id
    }
    store {
      id
      name
      image
    }
  }
}
    `;

/**
 * __useGetOrdersToRateQuery__
 *
 * To run a query within a React component, call `useGetOrdersToRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersToRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersToRateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersToRateQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersToRateQuery, GetOrdersToRateQueryVariables>) {
        return Apollo.useQuery<GetOrdersToRateQuery, GetOrdersToRateQueryVariables>(GetOrdersToRateDocument, baseOptions);
      }
export function useGetOrdersToRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersToRateQuery, GetOrdersToRateQueryVariables>) {
          return Apollo.useLazyQuery<GetOrdersToRateQuery, GetOrdersToRateQueryVariables>(GetOrdersToRateDocument, baseOptions);
        }
export type GetOrdersToRateQueryHookResult = ReturnType<typeof useGetOrdersToRateQuery>;
export type GetOrdersToRateLazyQueryHookResult = ReturnType<typeof useGetOrdersToRateLazyQuery>;
export type GetOrdersToRateQueryResult = Apollo.QueryResult<GetOrdersToRateQuery, GetOrdersToRateQueryVariables>;
export const GetOutletsDocument = gql`
    query GetOutlets($latitude: Float, $longitude: Float) {
  outlets(latitude: $latitude, longitude: $longitude) {
    id
    name
    image
    friendlyPath
    distance
    open
    close
  }
}
    `;

/**
 * __useGetOutletsQuery__
 *
 * To run a query within a React component, call `useGetOutletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletsQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useGetOutletsQuery(baseOptions?: Apollo.QueryHookOptions<GetOutletsQuery, GetOutletsQueryVariables>) {
        return Apollo.useQuery<GetOutletsQuery, GetOutletsQueryVariables>(GetOutletsDocument, baseOptions);
      }
export function useGetOutletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutletsQuery, GetOutletsQueryVariables>) {
          return Apollo.useLazyQuery<GetOutletsQuery, GetOutletsQueryVariables>(GetOutletsDocument, baseOptions);
        }
export type GetOutletsQueryHookResult = ReturnType<typeof useGetOutletsQuery>;
export type GetOutletsLazyQueryHookResult = ReturnType<typeof useGetOutletsLazyQuery>;
export type GetOutletsQueryResult = Apollo.QueryResult<GetOutletsQuery, GetOutletsQueryVariables>;
export const GetOutletDocument = gql`
    query GetOutlet($friendlyPath: String!, $deliveryOption: DeliveryOption!, $latitude: Float, $longitude: Float) {
  outlet(friendlyPath: $friendlyPath) {
    id
    name
    address
    stores(
      deliveryOption: $deliveryOption
      latitude: $latitude
      longitude: $longitude
    ) {
      ...StoreList
    }
  }
}
    ${StoreListFragmentDoc}`;

/**
 * __useGetOutletQuery__
 *
 * To run a query within a React component, call `useGetOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletQuery({
 *   variables: {
 *      friendlyPath: // value for 'friendlyPath'
 *      deliveryOption: // value for 'deliveryOption'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useGetOutletQuery(baseOptions: Apollo.QueryHookOptions<GetOutletQuery, GetOutletQueryVariables>) {
        return Apollo.useQuery<GetOutletQuery, GetOutletQueryVariables>(GetOutletDocument, baseOptions);
      }
export function useGetOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutletQuery, GetOutletQueryVariables>) {
          return Apollo.useLazyQuery<GetOutletQuery, GetOutletQueryVariables>(GetOutletDocument, baseOptions);
        }
export type GetOutletQueryHookResult = ReturnType<typeof useGetOutletQuery>;
export type GetOutletLazyQueryHookResult = ReturnType<typeof useGetOutletLazyQuery>;
export type GetOutletQueryResult = Apollo.QueryResult<GetOutletQuery, GetOutletQueryVariables>;
export const GetPopOutImagesDocument = gql`
    query GetPopOutImages {
  popUpImages {
    ...NoticeImage
  }
}
    ${NoticeImageFragmentDoc}`;

/**
 * __useGetPopOutImagesQuery__
 *
 * To run a query within a React component, call `useGetPopOutImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopOutImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopOutImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPopOutImagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPopOutImagesQuery, GetPopOutImagesQueryVariables>) {
        return Apollo.useQuery<GetPopOutImagesQuery, GetPopOutImagesQueryVariables>(GetPopOutImagesDocument, baseOptions);
      }
export function useGetPopOutImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopOutImagesQuery, GetPopOutImagesQueryVariables>) {
          return Apollo.useLazyQuery<GetPopOutImagesQuery, GetPopOutImagesQueryVariables>(GetPopOutImagesDocument, baseOptions);
        }
export type GetPopOutImagesQueryHookResult = ReturnType<typeof useGetPopOutImagesQuery>;
export type GetPopOutImagesLazyQueryHookResult = ReturnType<typeof useGetPopOutImagesLazyQuery>;
export type GetPopOutImagesQueryResult = Apollo.QueryResult<GetPopOutImagesQuery, GetPopOutImagesQueryVariables>;
export const PreviewPandagoDocument = gql`
    query PreviewPandago($outletId: ID, $storeId: ID, $address: String!, $lat: Float!, $long: Float!) {
  previewPandago(
    outletId: $outletId
    storeId: $storeId
    address: $address
    lat: $lat
    long: $long
  ) {
    ...PreviewDelivery
  }
}
    ${PreviewDeliveryFragmentDoc}`;

/**
 * __usePreviewPandagoQuery__
 *
 * To run a query within a React component, call `usePreviewPandagoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewPandagoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewPandagoQuery({
 *   variables: {
 *      outletId: // value for 'outletId'
 *      storeId: // value for 'storeId'
 *      address: // value for 'address'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *   },
 * });
 */
export function usePreviewPandagoQuery(baseOptions: Apollo.QueryHookOptions<PreviewPandagoQuery, PreviewPandagoQueryVariables>) {
        return Apollo.useQuery<PreviewPandagoQuery, PreviewPandagoQueryVariables>(PreviewPandagoDocument, baseOptions);
      }
export function usePreviewPandagoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewPandagoQuery, PreviewPandagoQueryVariables>) {
          return Apollo.useLazyQuery<PreviewPandagoQuery, PreviewPandagoQueryVariables>(PreviewPandagoDocument, baseOptions);
        }
export type PreviewPandagoQueryHookResult = ReturnType<typeof usePreviewPandagoQuery>;
export type PreviewPandagoLazyQueryHookResult = ReturnType<typeof usePreviewPandagoLazyQuery>;
export type PreviewPandagoQueryResult = Apollo.QueryResult<PreviewPandagoQuery, PreviewPandagoQueryVariables>;
export const PreviewLalamoveDocument = gql`
    query PreviewLalamove($outletId: ID, $storeId: ID, $address: String!, $lat: Float!, $long: Float!) {
  previewLalamove(
    outletId: $outletId
    storeId: $storeId
    address: $address
    lat: $lat
    long: $long
  ) {
    ...PreviewDelivery
  }
}
    ${PreviewDeliveryFragmentDoc}`;

/**
 * __usePreviewLalamoveQuery__
 *
 * To run a query within a React component, call `usePreviewLalamoveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewLalamoveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewLalamoveQuery({
 *   variables: {
 *      outletId: // value for 'outletId'
 *      storeId: // value for 'storeId'
 *      address: // value for 'address'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *   },
 * });
 */
export function usePreviewLalamoveQuery(baseOptions: Apollo.QueryHookOptions<PreviewLalamoveQuery, PreviewLalamoveQueryVariables>) {
        return Apollo.useQuery<PreviewLalamoveQuery, PreviewLalamoveQueryVariables>(PreviewLalamoveDocument, baseOptions);
      }
export function usePreviewLalamoveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewLalamoveQuery, PreviewLalamoveQueryVariables>) {
          return Apollo.useLazyQuery<PreviewLalamoveQuery, PreviewLalamoveQueryVariables>(PreviewLalamoveDocument, baseOptions);
        }
export type PreviewLalamoveQueryHookResult = ReturnType<typeof usePreviewLalamoveQuery>;
export type PreviewLalamoveLazyQueryHookResult = ReturnType<typeof usePreviewLalamoveLazyQuery>;
export type PreviewLalamoveQueryResult = Apollo.QueryResult<PreviewLalamoveQuery, PreviewLalamoveQueryVariables>;
export const GetStoresDocument = gql`
    query GetStores($latitude: Float, $longitude: Float, $searchString: String, $categoryId: ID, $deliveryOption: DeliveryOption!) {
  stores(
    latitude: $latitude
    longitude: $longitude
    searchString: $searchString
    categoryId: $categoryId
    deliveryOption: $deliveryOption
  ) {
    ...StoreList
  }
}
    ${StoreListFragmentDoc}`;

/**
 * __useGetStoresQuery__
 *
 * To run a query within a React component, call `useGetStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      searchString: // value for 'searchString'
 *      categoryId: // value for 'categoryId'
 *      deliveryOption: // value for 'deliveryOption'
 *   },
 * });
 */
export function useGetStoresQuery(baseOptions: Apollo.QueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
        return Apollo.useQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, baseOptions);
      }
export function useGetStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
          return Apollo.useLazyQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, baseOptions);
        }
export type GetStoresQueryHookResult = ReturnType<typeof useGetStoresQuery>;
export type GetStoresLazyQueryHookResult = ReturnType<typeof useGetStoresLazyQuery>;
export type GetStoresQueryResult = Apollo.QueryResult<GetStoresQuery, GetStoresQueryVariables>;
export const GetCartStoresByOutletDocument = gql`
    query GetCartStoresByOutlet($outletFriendlyPaths: [String!], $deliveryOption: DeliveryOption!) {
  stores(
    outletFriendlyPaths: $outletFriendlyPaths
    deliveryOption: $deliveryOption
  ) {
    id
    name
    friendlyPath
    active
  }
}
    `;

/**
 * __useGetCartStoresByOutletQuery__
 *
 * To run a query within a React component, call `useGetCartStoresByOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartStoresByOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartStoresByOutletQuery({
 *   variables: {
 *      outletFriendlyPaths: // value for 'outletFriendlyPaths'
 *      deliveryOption: // value for 'deliveryOption'
 *   },
 * });
 */
export function useGetCartStoresByOutletQuery(baseOptions: Apollo.QueryHookOptions<GetCartStoresByOutletQuery, GetCartStoresByOutletQueryVariables>) {
        return Apollo.useQuery<GetCartStoresByOutletQuery, GetCartStoresByOutletQueryVariables>(GetCartStoresByOutletDocument, baseOptions);
      }
export function useGetCartStoresByOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartStoresByOutletQuery, GetCartStoresByOutletQueryVariables>) {
          return Apollo.useLazyQuery<GetCartStoresByOutletQuery, GetCartStoresByOutletQueryVariables>(GetCartStoresByOutletDocument, baseOptions);
        }
export type GetCartStoresByOutletQueryHookResult = ReturnType<typeof useGetCartStoresByOutletQuery>;
export type GetCartStoresByOutletLazyQueryHookResult = ReturnType<typeof useGetCartStoresByOutletLazyQuery>;
export type GetCartStoresByOutletQueryResult = Apollo.QueryResult<GetCartStoresByOutletQuery, GetCartStoresByOutletQueryVariables>;
export const GetStoreWithMenuDocument = gql`
    query GetStoreWithMenu($friendlyPath: String!) {
  store(friendlyPath: $friendlyPath) {
    id
    name
    friendlyPath
    banner
    deliveryOption
    deliveryServices
    taxPercentage
    active
    menuSection {
      id
      menuCategories {
        id
        name
        availableStatus
        menuItems {
          id
          name
          description
          priceWithTax
          priceWithPromo
          withPromo
          image
          availableStatus
        }
      }
    }
    outlet {
      id
    }
  }
}
    `;

/**
 * __useGetStoreWithMenuQuery__
 *
 * To run a query within a React component, call `useGetStoreWithMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreWithMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreWithMenuQuery({
 *   variables: {
 *      friendlyPath: // value for 'friendlyPath'
 *   },
 * });
 */
export function useGetStoreWithMenuQuery(baseOptions: Apollo.QueryHookOptions<GetStoreWithMenuQuery, GetStoreWithMenuQueryVariables>) {
        return Apollo.useQuery<GetStoreWithMenuQuery, GetStoreWithMenuQueryVariables>(GetStoreWithMenuDocument, baseOptions);
      }
export function useGetStoreWithMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreWithMenuQuery, GetStoreWithMenuQueryVariables>) {
          return Apollo.useLazyQuery<GetStoreWithMenuQuery, GetStoreWithMenuQueryVariables>(GetStoreWithMenuDocument, baseOptions);
        }
export type GetStoreWithMenuQueryHookResult = ReturnType<typeof useGetStoreWithMenuQuery>;
export type GetStoreWithMenuLazyQueryHookResult = ReturnType<typeof useGetStoreWithMenuLazyQuery>;
export type GetStoreWithMenuQueryResult = Apollo.QueryResult<GetStoreWithMenuQuery, GetStoreWithMenuQueryVariables>;
export const GetStoreDetailsDocument = gql`
    query GetStoreDetails($friendlyPath: String!) {
  store(friendlyPath: $friendlyPath) {
    id
    friendlyPath
    description
    averageRating
    ratingsCount
    categories {
      id
      name
    }
    ongoingPromos {
      id
      name
    }
    ratings {
      id
      rate
    }
    menuSection {
      id
      openingHours {
        id
        day
        open
        close
      }
    }
  }
}
    `;

/**
 * __useGetStoreDetailsQuery__
 *
 * To run a query within a React component, call `useGetStoreDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreDetailsQuery({
 *   variables: {
 *      friendlyPath: // value for 'friendlyPath'
 *   },
 * });
 */
export function useGetStoreDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetStoreDetailsQuery, GetStoreDetailsQueryVariables>) {
        return Apollo.useQuery<GetStoreDetailsQuery, GetStoreDetailsQueryVariables>(GetStoreDetailsDocument, baseOptions);
      }
export function useGetStoreDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreDetailsQuery, GetStoreDetailsQueryVariables>) {
          return Apollo.useLazyQuery<GetStoreDetailsQuery, GetStoreDetailsQueryVariables>(GetStoreDetailsDocument, baseOptions);
        }
export type GetStoreDetailsQueryHookResult = ReturnType<typeof useGetStoreDetailsQuery>;
export type GetStoreDetailsLazyQueryHookResult = ReturnType<typeof useGetStoreDetailsLazyQuery>;
export type GetStoreDetailsQueryResult = Apollo.QueryResult<GetStoreDetailsQuery, GetStoreDetailsQueryVariables>;