import {OrderState} from '@graphql/generated/graphql';

export const DELIVER_TO_COUNTRY = 'COUNTRY';
export const DELIVER_TO_ADDRESS = 'ADDRESS';
export const DELIVER_TO_LATITUDE = 'LATITUDE';
export const DELIVER_TO_LONGITUDE = 'LONGITUDE';

export const DEFAULT_LOCATIONS = {
  MY: {
    address: 'COOX Kitchens, Syopz Mall',
    lat: 3.063674418500119,
    lng: 101.61639935244528,
  },
  SG: {
    address: 'Marina Bay Sands',
    lat: 1.2837562329103667,
    lng: 103.85910641282372,
  },
  AE: {
    address: 'Burj Khalifa',
    lat: 25.197206685682154,
    lng: 55.27437639626292,
  },
};

export const COOKIE_OPTIONS = {
  maxAge: 60 * 60 * 24 * 7,
  path: '/',
};

export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  arr1.sort((a, b) => a - b);
  arr2.sort((a, b) => a - b);
  return arr1.length === arr2.length && arr1.every((e, index) => e === arr2[index]);
};

export const ORDERS_STATES = [OrderState.Finished, OrderState.Cancelled];
export const PREORDERS_STATES = [OrderState.Scheduled];

export const ONGOING_ORDERS_STATES = [OrderState.Accepted, OrderState.Readied, OrderState.Collected];

export const getIds = (arr: {id: string}[]): string[] => arr.map(({id}) => id);
