import React from 'react';
import {useRouter} from 'next/router';
import {useMixOrderStore} from 'stores';

interface IOutletPreorderButtonProps {}

const OutletPreorderButton = ({}: IOutletPreorderButtonProps) => {
  const router = useRouter();
  const outletFriendlyPath = router.query.friendlyPath as string;

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const removeCartOutlet = useMixOrderStore(s => s.removeCartOutlet);
  const hasCart = !!mixOrderInput?.stores?.length;

  const handleClick = () => {
    if (!hasCart) {
      return router.push(`/outlets/${outletFriendlyPath}/preorder`);
    }
    if (window.confirm('Your cart will be cleared, proceed?')) {
      removeCartOutlet(outletFriendlyPath);
      router.push(`/outlets/${outletFriendlyPath}/preorder`);
    }
  };

  return (
    <button
      type="button"
      className="flex justify-center items-center gap-1 poppins-semibold py-0.5 px-2 mt-0.5 -mb-1 rounded-full transition duration-300 text-sm text-primary grayRoundBorder hover:text-white hover:bg-primary hover:border-primary"
      onClick={handleClick}>
      {CLOCK_SVG}
      <span>PREORDER</span>
    </button>
  );
};

export default OutletPreorderButton;

const CLOCK_SVG = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
);
