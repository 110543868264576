import React, {useEffect} from 'react';
import {DeliveryOption} from '@graphql/generated/graphql';
import {DELIVERY_SELECTIONS} from 'modules';
import {usePersistentStore} from 'stores';

interface ISearchDeliveryOptionProps {
  deliveryOptions?: DeliveryOption[];
}

const SearchDeliveryOption: React.FC<ISearchDeliveryOptionProps> = React.memo(({deliveryOptions}) => {
  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const setDeliveryOption = usePersistentStore(s => s.setDeliveryOption);

  const options = deliveryOptions ?? Object.values(DeliveryOption);
  const deliveryTabs = options.map(option => DELIVERY_SELECTIONS[option]).filter(value => Object.keys(value).length !== 0);

  useEffect(() => {
    if (!options.includes(deliveryOption)) {
      setDeliveryOption(options[0]);
    }
  }, [options, deliveryOption, setDeliveryOption]);

  return (
    <section className="max-w-md z-10 py-3 bg-white defaultShadow px-3 flex">
      {deliveryTabs.map(({text, option}) => (
        <div
          key={option}
          onClick={() => setDeliveryOption(option)}
          className={`w-full text-center poppins-semibold py-0.5 mx-2 cursor-pointer rounded-full transition duration-100 ${
            deliveryOption === option ? 'text-white bg-primary' : 'text-gray grayRoundBorder'
          }`}>
          {text}
        </div>
      ))}
    </section>
  );
});

export default SearchDeliveryOption;
