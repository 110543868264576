import React from 'react';

interface IPreorderChangeDateButtonProps {
  onClick: () => void;
}

const PreorderChangeDateButton = ({onClick}: IPreorderChangeDateButtonProps) => {
  return (
    <button
      type="button"
      className="flex justify-center items-center gap-1 poppins-semibold py-0.5 px-2 mt-0.5 -mb-1 rounded-full transition duration-300 text-sm text-primary grayRoundBorder hover:text-white hover:bg-primary hover:border-primary"
      onClick={onClick}>
      {CLOCK_SVG}
      <span>EDIT DATE</span>
    </button>
  );
};

export default PreorderChangeDateButton;

const CLOCK_SVG = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
);
