import React, {Fragment} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {StoreListFragment} from '@graphql/generated/graphql';
import {Dot} from '..';
import {useLoader} from 'context';
import {useMixOrderStore, usePersistentStore} from 'stores';

interface IStoreCardProps {
  store: StoreListFragment;
  showDistance?: boolean;
}

const StoreCard: React.FC<IStoreCardProps> = ({
  store: {
    id,
    friendlyPath: storeFriendlyPath,
    name,
    image,
    categories,
    active,
    averageRating,
    ongoingPromos,
    priceSymbol,
    distance: rawDistance,
    outlet: {friendlyPath: outletFriendlyPath},
  },
  showDistance = false,
}) => {
  const {setShowLoader} = useLoader();
  const preorderDate = usePersistentStore(s => s.preorderDate);
  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const outletStore = mixOrderInput.stores.find(({storeId}) => storeId === id);
  const foodCount = outletStore?.menuItems?.reduce((acc, {count}) => acc + count, 0) || 0;
  const isActive = preorderDate ? true : active;
  const distance = rawDistance ?? -1;

  return (
    <Link href={`/outlets/${outletFriendlyPath}/${storeFriendlyPath}`} scroll={false}>
      <a onClick={() => setShowLoader(true)}>
        <div className={`flex flex-row items-center py-3 marginHorizontal lightGrayBottomBorder ${isActive ? 'opacity-100' : 'opacity-50'}`}>
          <div className="relative mr-4">
            <img src={image ?? '/image-placeholder.jpg'} width={110} height={110} className="rounded-xl" />

            {!!foodCount && (
              <div className="absolute rounded-full -top-1 -right-2 w-7 h-7 bg-black flex flex-row items-center justify-center">
                <p className="poppins-semibold font-sm self-center text-white">{foodCount}x</p>
              </div>
            )}
          </div>

          <div className="flex-1">
            {!isActive && <p className="closed opacity-50">CLOSED</p>}
            {!!ongoingPromos.length && <p className="text-primary font-sm poppins-semibold mb-1">PROMO</p>}

            <p className="poppins-semibold">{name}</p>

            {!!averageRating && (
              <div className="flex flex-row justify-start items-center gap-1">
                <Image src="/icons/star-filled.svg" width={15} height={15} />
                <p className="poppins-medium font-sm">{averageRating.toFixed(1)}/5</p>
              </div>
            )}

            <div className="my-1.5">
              {showDistance && distance >= 0 && (
                <div className="flex flex-row gap-1 justify-start items-center">
                  <svg className="w-3 h-3 mb-0.5" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 0C9.3135 0 12 2.6865 12 6C12 7.30475 11.5722 8.502 10.8612 9.4825C10.8485 9.506 10.8465 9.53225 10.832 9.55475L6.832 15.5548C6.6465 15.833 6.334 16 6 16C5.666 16 5.3535 15.833 5.168 15.5548L1.168 9.55475C1.15325 9.53225 1.1515 9.506 1.13875 9.4825C0.42775 8.502 0 7.30475 0 6C0 2.6865 2.6865 0 6 0ZM6 8C7.1045 8 8 7.1045 8 6C8 4.8955 7.1045 4 6 4C4.8955 4 4 4.8955 4 6C4 7.1045 4.8955 8 6 8Z"
                      fill="#b7b7b7"
                    />
                  </svg>
                  <p className="subTextGrayRegular">{distance.toFixed(2)} km</p>
                </div>
              )}
              <div className="flex flex-row justify-start items-start">
                <p className="subTextGrayRegular">{priceSymbol}</p>
                {!!categories.length && (
                  <div className="flex flex-row flex-wrap items-center ml-3">
                    {categories.map(({id, name}, index) => {
                      const isLast = index === categories.length - 1;
                      return (
                        <Fragment key={id}>
                          <p className="subTextGrayRegular">{name}</p>
                          {!isLast && <Dot color="bg-gray" />}
                        </Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              {!!ongoingPromos.length
                ? ongoingPromos.map(({id, name}, index) => {
                    const isLast = index === ongoingPromos.length - 1;
                    return (
                      <div key={id} className={`${!isLast ? 'mr-0.5' : 'mr-0'} shrink bg-primary rounded-xl mt-1 py-0.5 px-1.5`}>
                        <p className="text-white poppins-medium font-xs">{name}</p>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default React.memo(StoreCard);
