import React from 'react';
import {useAnnouncementQuery} from '@graphql/generated/graphql';

interface IAnnouncementProps {}

const Announcement = ({}: IAnnouncementProps) => {
  const {data} = useAnnouncementQuery();
  const announcement = data?.announcement;
  if (!announcement) {
    return null;
  }
  return (
    <div className="bg-orange-200 text-black font-medium py-2 px-10">
      <p className="relative text-center text-orange-800 text-sm">
        <span className="absolute left-0 transform -translate-x-[1.2rem]">{EXCLAMATION_SVG}</span>
        {announcement}
      </p>
    </div>
  );
};

export default Announcement;

const EXCLAMATION_SVG = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 pb-0.5">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
    />
  </svg>
);
