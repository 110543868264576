import {OrderState} from '@graphql/generated/graphql';

const MAPS: Record<OrderState, string> = {
  [OrderState.Unfulfilled]: 'Unfulfilled',
  [OrderState.Scheduled]: 'Scheduled',
  [OrderState.Accepted]: 'Preparing',
  [OrderState.Readied]: 'To collect',
  [OrderState.Collected]: 'Delivering',
  [OrderState.Finished]: 'Delivered',
  [OrderState.Cancelled]: 'Cancelled',
};

const orderStateMapping = (state: OrderState) => {
  return MAPS[state];
};

export default orderStateMapping;
